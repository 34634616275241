import React from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import style from '../RbpLanding.module.css';
import promo_img from '../../../assets/RbpLandingImages/Promo/promo_img.png';
import { $language } from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import useWindowSize from '../../../hooks/useWindowSize.js';

function Promo() {
  const language = useUnit($language);
  const navigate = useNavigate();
  const [width, height] = useWindowSize();

  const onClick = () => {
    document.getElementById('prices').scrollIntoView();
  };

  return (
    <div className={style.promo_wrapper}>
      <div className={style.container}>
        <div className={style.promo}>
          <div className={style.promo_info}>
            <div className={style.promo_title}>
              <div className={style.promo_rbp}>Resilient Business Platform</div>
              {' - '}
              {width <= 1200 ? <br /> : null}
              {rbp_landing_translation[language].promo_title}
            </div>
            <div className={style.promo_subtitile}>
              {rbp_landing_translation[language].promo_subtitle}
            </div>
            <Button
              className={style.promo_button}
              icon={<ArrowRightOutlined />}
              iconPosition="end"
              onClick={onClick}
            >
              {rbp_landing_translation[language].promo_button}
            </Button>
          </div>
          <img src={promo_img} className={style.promo_img} />
          <Button
            className={style.promo_button_small}
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            onClick={onClick}
          >
            {rbp_landing_translation[language].promo_button}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Promo;
