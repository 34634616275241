import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  $chartFilters,
  changeChartFiltersEv,
} from '../../../models/rbpLiteModel/index.js';
import {
  age_dictionary,
  income_dictionary,
} from '../../../dictionaries/socdem_dictionaries.js';
import { charts_names_dict } from './charts_names_dict.js';
import { addSeparators } from '../../../utils/format-utils.js';

function LitePieChart({ data, metrics, bt = null }) {
  const darkMode = useUnit($isDarkTheme);
  const chartFilters = useUnit($chartFilters);
  const changeChartFilters = useUnit(changeChartFiltersEv);
  const chartRef = useRef();

  const handleChartClick = (event, chartContext, config) => {
    // FIXME requires fixes
    const name = config.w.config.labels[config.dataPointIndex];
    const category = config.w.config.xaxis.categories[config.dataPointIndex];

    const payload = {
      category: name,
      column: category,
      metrics,
      bt,
      // chart: title,
    };

    changeChartFilters(payload);
  };

  const options = {
    // labels: Object.keys(data).map((key) => charts_names_dict[key]),
    labels: Object.keys(data),
    legend: {
      position: 'bottom',
      formatter: (value) => {
        return charts_names_dict[value];
      },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      events: {
        dataPointSelection: handleChartClick,
        dataPointMouseEnter(event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => addSeparators(value),
        title: {
          formatter: (value) => {
            return charts_names_dict[value];
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'lighten',
          value: 0.3,
        },
      },
    },
  };

  const series = Object.keys(data).map((item) => data[item]);

  useEffect(() => {
    const { selectedDataPoints } = chartRef.current.chart.w.globals;
    if (
      (chartFilters.length > 0 && chartFilters[0].metrics !== metrics) ||
      (chartFilters.length === 0 && selectedDataPoints.length > 0)
    ) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));
      if (copy.length > 0) {
        copy.forEach((item, seriesIndex) => {
          if (item && item.length > 0) {
            item.forEach((dataIndex) => {
              chartRef.current.chart.ctx.toggleDataPointSelection(
                seriesIndex,
                dataIndex
              );
            });
          }
        });
      }
    }
    if (chartFilters.length > 0 && chartFilters[0].metrics === metrics) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));

      const dict = Object.hasOwn(chartFilters[0], 'age')
        ? age_dictionary
        : income_dictionary;
      const field = chartFilters[0].age ? 'age' : 'income';
      const chartLabels = chartRef.current.chart.w.globals.labels;
      if (copy.length === 0) {
        chartFilters.forEach((filter) => {
          const seriesIndex = +filter.gender - 1;
          const element = chartLabels.find((item) =>
            item.includes(dict[filter[field]])
          );
          const dataPointIndex = chartLabels.indexOf(element);

          chartRef.current.chart.ctx.toggleDataPointSelection(
            seriesIndex,
            dataPointIndex
          );
        });
      }
    }
  }, [chartFilters]);

  return (
    <Chart
      ref={chartRef}
      options={options}
      series={series}
      type="pie"
      width={390}
      padding="15"
    />
  );
}

export default LitePieChart;
