import { useUnit } from 'effector-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { Card, Col, Row, Table } from 'antd';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import style from './UserView.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import { getUserFx, $userViewInfo } from '../../models/userViewModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

export default function UserView() {
  const getUser = useUnit(getUserFx);
  const loading = useUnit(getUserFx.pending);
  const userViewInfo = useUnit($userViewInfo);
  const language = useUnit($language);
  const userInfo = useUnit($userInfo);
  const { id } = useParams();
  const navigate = useNavigate();
  const darkMode = useUnit($isDarkTheme);
  const donwloadChartRef = useRef();
  const mutateChartRef = useRef();

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      y: {
        formatter: (value) => `${value} ms`,
      },
    },
  };

  const barOptions = {
    xaxis: {
      categories:
        userViewInfo?.timers?.map((item) =>
          dayjs(item.created_at).format('DD.MM.YYYY-HH:mm:ss')
        ) || [],
    },
  };

  const columns = [
    {
      title: ru_en_page_dictionary.user_table_date[language],
      dataIndex: '',
      key: 'date',
      render: (text, record) => {
        return dayjs(record.created_at).format('DD.MM.YYYY-HH:mm:ss');
      },
    },
    {
      title: ru_en_page_dictionary.user_table_download[language],
      dataIndex: 'download_ms',
      key: 'download',
      render: (text, record) => `${text} ms`,
    },
    {
      title: ru_en_page_dictionary.user_table_mutation[language],
      dataIndex: 'mutate_ms',
      key: 'mutate',
      render: (text, record) => `${text} ms`,
    },
  ];

  const downloadSeries = [
    {
      name: 'Загрузка',
      data: userViewInfo?.timers?.map((item) => item.download_ms) || [],
    },
  ];

  const mutateSeries = [
    {
      name: 'Мутация',
      data: userViewInfo?.timers?.map((item) => item.mutate_ms) || [],
    },
  ];

  useEffect(() => {
    if (id !== null && Object.keys(userViewInfo).length === 0) {
      getUser(id);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      if (!userInfo.perms.includes('admin')) {
        navigate('/lite');
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (donwloadChartRef.current && mutateChartRef.current) {
      donwloadChartRef.current.chart.ctx.updateOptions({
        ...options,
        ...barOptions,
      });
      mutateChartRef.current.chart.ctx.updateOptions({
        ...options,
        ...barOptions,
      });
    }
  }, [darkMode]);

  return (
    <div className={style.container}>
      <div
        className={style.user_view_title}
        style={{ color: darkMode ? 'white' : 'black' }}
      >
        {ru_en_page_dictionary.user[language]}: {userViewInfo.name}
      </div>
      <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Card>
            <div className={style.chart_title}>
              {ru_en_page_dictionary.chart_download[language]}
            </div>
            <Chart
              type="bar"
              series={downloadSeries}
              options={{ ...options, ...barOptions }}
              ref={donwloadChartRef}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <div className={style.chart_title}>
              {ru_en_page_dictionary.chart_mutation[language]}
            </div>
            <Chart
              type="bar"
              series={mutateSeries}
              options={{ ...options, ...barOptions }}
              ref={mutateChartRef}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={
              userViewInfo?.timers?.map((item, index) => ({
                ...item,
                key: `user-timer-${index}`,
              })) || []
            }
            scroll={{
              y: 290,
            }}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
}
