import { createEvent } from 'effector';

export const changeWebSocketConnectedEv = createEvent();

export const showLoaderEv = createEvent();
export const hideLoaderEv = createEvent();

export const delayedHideLoaderEv = createEvent();

export const showRadarLoaderEv = createEvent();
export const showCardsLoaderEv = createEvent();
export const hideRadarLoaderEv = createEvent();
export const hideCardsLoaderEv = createEvent();

export const initPublicWsEv = createEvent()
