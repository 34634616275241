import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { $mapLoaded } from '../../../models/mapModel/index.js';
import {
  $routingPoints,
  $routingPointType,
  changeRoutingPointsEv,
} from '../../../models/tezberModel/index.js';

const handleClick = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: ['routing_points'],
  });

  if (features.length > 0) {
    changeRoutingPointsEv(features[0]);
  } else {
    changeRoutingPointsEv({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: {},
    });
  }
};

const handleCursor = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: ['routing_points'],
  });
  // Change the cursor to a pointer when hovering over a point on the map.
  // Otherwise cursor is a crosshair.
  window.map.getCanvas().style.cursor = features.length
    ? 'pointer'
    : 'crosshair';
};

function RoutingLayer() {
  const mapLoaded = useUnit($mapLoaded);
  const routingPointType = useUnit($routingPointType);
  const routingPoints = useUnit($routingPoints);

  const createLayer = (update) => {
    const routingData = {
      type: 'FeatureCollection',
      features: routingPoints,
    };

    if (update) {
      window.map.getSource('routing_geojson').setData(routingData);
    } else {
      if (!window.map.getSource('routing_geojson')) {
        window.map.addSource('routing_geojson', {
          type: 'geojson',
          data: routingData,
        });
      }
      if (!window.map.getLayer('routing_points')) {
        window.map.addLayer({
          id: 'routing_points',
          type: 'circle',
          source: 'routing_geojson',
          paint: {
            'circle-radius': 5,
            'circle-color': [
              'match',
              ['get', 'type'],
              'start',
              '#33ff57',
              'finish',
              '#ff5733',
              '#ccc',
            ],
          },
        });
      }
    }
  };

  useEffect(() => {
    if (mapLoaded) {
      if (window.map.getLayer('routing_points')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [mapLoaded, routingPoints]);

  useEffect(() => {
    if (mapLoaded) {
      if (routingPointType) {
        window.map.on('click', handleClick);
        window.map.on('mousemove', handleCursor);
      } else {
        window.map.off('click', handleClick);
        window.map.off('mousemove', handleCursor);
        window.map.on('mousemove', () => {
          window.map.getCanvas().style.cursor = 'default';
        });
      }
    }
  }, [mapLoaded, routingPointType]);

  return null;
}

export default RoutingLayer;
