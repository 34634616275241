import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { Divider, Popover, Tooltip } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';
import style from './LiteCategory.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
  clearGradientEv,
} from '../../../models/activeFiltersModel/index.js';
import {
  BusIcon,
  CompetitorsIcon,
  HouseholdsIcon,
  icons_dict,
  IncomeIcon,
  IncomeWorkingIcon,
  PopulationIcon,
  PotentialIcon,
  RentIcon,
  SpendingsIcon,
  TrafficIcon,
  WorkingIcon,
} from './icons.jsx';
import LiteCategoryDetails from '../LiteCategoryDetails/LiteCategoryDetails.jsx';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  $currentFavs,
  $liteCategories,
  toggleShowRoadsEv,
} from '../../../models/rbpLiteModel/index.js';
import { addSeparators } from '../../../utils/format-utils.js';

function LiteCategory() {
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const clearGradient = useUnit(clearGradientEv);
  const [expanded, setExpanded] = useState(true);
  const isDark = useUnit($isDarkTheme);
  const [isFav, setIsFav] = useState(false);
  const currentFavs = useUnit($currentFavs);
  const liteCategories = useUnit($liteCategories);
  const toggleShowRoad = useUnit(toggleShowRoadsEv);
  // FIXME if the template is wrong use indexCards to form items
  // const indexCards = useUnit($indexCards);

  const changeCategory = (value) => {
    if (isFav) setIsFav(false);
    if (chosen_metric === value) {
      setExpanded(!expanded);
      return;
    }
    clearGradient();
    toggleShowRoad(false);
    changeActiveFilter({
      field: 'chosen_metrics',
      value,
    });
    if (expanded) {
      setExpanded(false);
    }
  };

  return (
    // <div className={style.public_category}>
    <>
      <div
        className={[
          style.public_category_wrapper,
          expanded && style.expanded,
        ].join(' ')}
        style={{
          background: isDark ? '#1e2328' : 'white',
        }}
      >
        {liteCategories.map((item, index) => {
          return (
            <LiteCategoryItem
              key={`category-${item.index_name}-${index}`}
              chosenMetric={chosen_metric}
              expanded={expanded}
              changeCategory={changeCategory}
              isDark={isDark}
              isFav={isFav}
              icon={icons_dict[item.index_name]}
              {...item}
            />
          );
        })}
        {!expanded ? (
          <>
            <LiteCategoryItem
              disabled={false}
              key="favorites"
              chosenMetric={chosen_metric}
              expanded={expanded}
              changeCategory={() => setIsFav(true)}
              isDark={isDark}
              icon={<StarOutlined />}
              index_name="favorites"
              isFav={isFav}
              title="Избранное"
            />
            <Divider style={{ margin: '12px 0' }} />
            <div
              className={style.expand_button}
              onClick={() => setExpanded(!expanded)}
            >
              <DoubleLeftOutlined />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <LiteCategoryDetails expanded={expanded} isFav={isFav} />
    </>
    // </div>
  );
}

function LiteCategoryItem(props) {
  const {
    title,
    index_name,
    icon,
    info,
    chosenMetric,
    avg,
    total,
    value,
    expanded,
    changeCategory,
    isDark,
    isFav,
    disabled,
    sub_value,
    sub_avg,
  } = props;

  const popoverContent = (
    <div
      style={{
        maxWidth: 600,
      }}
    >
      <div className={style.popover_title}>{title}</div>
      {info?.match(/<.+?>/g) ? (
        <div
          className={style.popover_info}
          dangerouslySetInnerHTML={{ __html: info }}
        />
      ) : (
        <div className={style.popover_info}>{info}</div>
      )}
    </div>
  );

  return (
    <Tooltip title={title} placement="left">
      <div
        className={[
          style.public_category_item,
          !isFav
            ? chosenMetric === index_name
              ? style.active
              : ''
            : index_name === 'favorites'
              ? style.active
              : '',
        ].join(' ')}
        onClick={
          !disabled
            ? () => {
                changeCategory(index_name);
              }
            : () => {}
        }
        style={{
          padding: expanded ? '10px 10px 20px 10px' : '10px',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <div
          className={style.public_category_item_top}
          style={{
            justifyContent: expanded ? 'space-between' : 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: 10,
            }}
          >
            {icon}
            {expanded && (
              <div style={{ color: isDark ? 'white' : 'black' }}>{title}</div>
            )}
          </div>
          {expanded && (
            <Popover content={popoverContent} placement="left">
              <InfoCircleOutlined className={style.public_category_info} />
            </Popover>
          )}
          {/* <div className={style.public_category_popup}> */}
          {/*  <div className={style.public_category_popup_title}>{title}</div> */}
          {/*  {info} */}
          {/* </div> */}
        </div>
        {expanded ? (
          <>
            <div
              className={style.gradient_line}
              style={{
                background: isDark
                  ? 'linear-gradient(90deg, rgba(0,0,69,1) 0%, rgba(51,10,95,1) 5%, rgba(121,28,110,1) 24%, rgba(188,55,85,1) 49%, rgba(238,105,37,1) 72%, rgba(228,237,194,1) 95%, rgba(253,255,165,1) 100%)'
                  : 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(248,88,0,1) 5%, rgba(241,182,9,1) 24%, rgba(210,253,97,1) 49%, rgba(72,184,42,1) 72%, rgba(14,107,0,1) 95%, rgba(3,31,3,1) 100%)',
              }}
            >
              <div
                className={style.city_avg_wrapper}
                style={{
                  left: `${Math.round((avg * 100) / total)}%`,
                }}
              >
                <div
                  className={style.city_avg_line}
                  style={{
                    background: isDark ? 'white' : '#717171',
                  }}
                />
              </div>
              <div
                className={style.category_value}
                style={{
                  left: `${Math.round((value * 100) / total)}%`,
                }}
              >
                <div className={style.category_value_pointer}>
                  <div
                    className={style.category_value_num}
                    style={{
                      // marginLeft: `${Math.round((value * 100) / total)}%`,
                      color: isDark ? 'white' : 'black',
                    }}
                  >
                    {addSeparators(
                      sub_value !== undefined && sub_value !== null
                        ? sub_value
                        : value
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={style.city_avg}
              style={{
                color: isDark ? 'white' : '#717171',
                marginLeft: `${Math.round((avg * 100) / total) / 2}%`,
              }}
            >
              {`${addSeparators(
                sub_avg !== undefined && sub_avg !== null ? sub_avg : avg
              )} среднее по городу`}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  );
}

export default LiteCategory;
