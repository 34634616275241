import { sample } from 'effector';
import {
  calculateThresholdsEv,
  changeBucketAlgorithmEv,
  putBlockedBucketsEv,
  putThresholdsEv,
  resetBlockedBucketsEv,
} from './events.js';
import {
  $blockedBuckets,
  $bucketAlgorithm,
  $gradientBuckets,
} from './stores.js';
import {
  $activeFilters,
  clearActiveGradientEv,
  selectAllEv,
} from '../activeFiltersModel/index.js';
import { $isDarkTheme, $userInfo } from '../authModel/index.js';
import { $zoom10Hexagons } from '../zoom10Model/index.js';
import { bucketsByAlgorithmFx } from './effects.js';
import { delayedHideLoaderEv, showLoaderEv } from '../webSocketModel/index.js';
import { getLessColors } from '../../utils/gradient-utils.js';
import { gradient_colors } from '../../dictionaries/gradient_dictionary.js';
import { $zoom9Hexagons } from '../zoom9Model/index.js';
import { $rbpLayer } from '../mapModel/index.js';
import { $isPreset } from '../rbpLiteModel/index.js';
import { $secondGradient } from '../rbpLandingModel/index.js';

$blockedBuckets.reset(resetBlockedBucketsEv);

sample({
  source: $blockedBuckets,
  clock: putBlockedBucketsEv,
  fn: (source, clock) => {
    return {
      ...source,
      [clock.zoom]: clock.value,
    };
  },
  target: $blockedBuckets,
});

sample({
  clock: selectAllEv,
  target: resetBlockedBucketsEv,
});

sample({
  source: [$gradientBuckets, $secondGradient],
  clock: $isDarkTheme.updates,
  fn: ([buckets, secondGradient], clock) => {
    let colors;
    if (clock) {
      colors = gradient_colors.dark;
      if (secondGradient) colors = gradient_colors.lightV2;
    } else {
      colors = gradient_colors.light;
      if (secondGradient) colors = gradient_colors.lightV2;
    }
    if (window.location.pathname === '/public') {
      colors = gradient_colors.public;
    }
    return {
      ...buckets,
      colors,
    };
  },
  target: $gradientBuckets,
});

sample({
  source: [$userInfo, $bucketAlgorithm, $activeFilters, $rbpLayer],
  clock: calculateThresholdsEv,
  filter: ([userInfo, _, activeFilters, rbpLayer]) =>
    activeFilters.gradient.length === 0,
  fn: ([userInfo, algorithm, _, rbpLayer], clock) => {
    return {
      hexagons: clock,
      algorithm,
      rbpLayer,
    };
  },
  target: bucketsByAlgorithmFx,
});

sample({
  source: [$gradientBuckets, $isDarkTheme, $isPreset, $secondGradient],
  clock: putThresholdsEv,
  fn: ([gradientBuckets, darkMode, isPreset, secondGradient], clock) => {
    const buckets = clock;
    let { colors } = gradientBuckets;
    if (clock.length - 1 < 10) {
      colors = getLessColors(
        clock.length - 1,
        darkMode,
        isPreset,
        secondGradient
      );
    } else {
      colors =
        gradient_colors[
          secondGradient ? 'lightV2' : darkMode ? 'dark' : 'light'
        ];
      if (
        window.location.pathname === '/public' ||
        (window.location.pathname === '/lite' && !isPreset)
      ) {
        colors = gradient_colors.public;
      }
    }
    return {
      buckets,
      colors,
    };
  },
  target: $gradientBuckets,
});

sample({
  clock: changeBucketAlgorithmEv,
  fn: (clock) => clock,
  target: [$bucketAlgorithm, clearActiveGradientEv],
});

sample({
  source: $zoom9Hexagons,
  clock: $bucketAlgorithm.updates,
  fn: (hexagons, clock) => {
    return {
      hexagons,
      algorithm: clock,
    };
  },
  target: [bucketsByAlgorithmFx, showLoaderEv],
});

sample({
  clock: bucketsByAlgorithmFx.doneData,
  target: [putThresholdsEv, delayedHideLoaderEv],
});

sample({
  source: [$gradientBuckets, $isDarkTheme],
  clock: $secondGradient.updates,
  fn: ([buckets, isDark], clock) => {
    if (clock) {
      return {
        ...buckets,
        colors:
          buckets.buckets.length - 1 < 10
            ? getLessColors(buckets.buckets.length - 1, false, false, true)
            : gradient_colors.lightV2,
      };
    }
    return {
      ...buckets,
      colors:
        buckets.buckets.length - 1 < 10
          ? getLessColors(buckets.buckets.length - 1, isDark, false, false)
          : gradient_colors[isDark ? 'dark' : 'light'],
    };
  },
  target: $gradientBuckets,
});
