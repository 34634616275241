import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { $mapLoaded } from '../../models/mapModel/index.js';
import { $roadsData, $showRoads } from '../../models/rbpLiteModel/index.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import { formDynamicBuckets } from '../../utils/hexagon-utils.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';

function LiteRoadsLayer() {
  const mapLoaded = useUnit($mapLoaded);
  const showRoads = useUnit($showRoads);
  const roadsData = useUnit($roadsData);
  const { buckets, colors } = useUnit($gradientBuckets);

  const createLayer = (update) => {
    const sourceData = {
      type: 'FeatureCollection',
      features: roadsData,
    };
    const gradientProp = formDynamicBuckets(buckets, colors);

    if (update) {
      window.map.getSource('lite_roads_source').setData(sourceData);
      window.map.setPaintProperty(
        'lite_roads_layer',
        'line-color',
        gradientProp
      );
    } else {
      if (!window.map.getSource('lite_roads_source')) {
        window.map.addSource('lite_roads_source', {
          type: 'geojson',
          data: sourceData,
        });
      }

      if (!window.map.getLayer('lite_roads_layer')) {
        window.map.addLayer({
          id: 'lite_roads_layer',
          type: 'line',
          source: 'lite_roads_source',
          paint: {
            'line-color': gradientProp,
            'line-width': 2,
          },
        });

        changeLayerVisibility('lite_roads_layer', false);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && roadsData.length > 0 && buckets.length > 0) {
      if (window.map.getLayer('lite_roads_layer')) {
        createLayer(true);
      }
    } else {
      setTimeout(() => {
        createLayer(false);
      }, 500);
    }
  }, [mapLoaded, roadsData, buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('lite_roads_layer')) {
      if (showRoads) {
        changeLayerVisibility('lite_roads_layer', true);
      } else {
        changeLayerVisibility('lite_roads_layer', false);
      }
    }
  }, [mapLoaded, showRoads]);

  return null;
}

export default LiteRoadsLayer;
