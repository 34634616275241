export const almaty_business_chats_info = [
  {
    route: 'chat',
    apiUrl: 'https://atamekenvc.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я здесь, чтобы помочь вам с информацией по созданию и развитию стартапа, а также по вопросам привлечения инвестиций. 
Задавайте любые вопросы — от первых шагов в запуске до рекомендаций по привлечению инвестиций и существующих программ поддержки.`,
  },
  {
    route: 'chat-v2',
    apiUrl: 'https://atameken-2.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я - цифровой помощник для предпринимателей, упрощающий доступ к информации о государственных мерах поддержки и финансовым инструментам. Помогаю быстрее ориентироваться в доступных возможностях для роста бизнеса.`,
  },
  {
    route: 'banking',
    apiUrl: 'https://banking.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник по финансовым продуктам банков второго уровня. Помогу выбрать кредит, разобраться с факторингом, лизингом или другими инструментами для вашего бизнеса.`,
  },
  {
    route: 'crowdfunding',
    apiUrl: 'https://crowdfunding.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник, который поможет вам разобраться в мире краудфандинга. Подскажу, как выбрать платформу, создать эффективную кампанию и привлечь финансирование для вашего проекта. Вместе мы сделаем ваш проект успешным.`,
  },
  {
    route: 'realestate',
    apiUrl: 'https://realestate.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах регистрации прав на недвижимость. Помогу разобраться в процедурах, документах и этапах регистрации, чтобы процесс стал для вас понятным и удобным.`,
  },
  {
    route: 'elnet',
    apiUrl: 'https://elnet.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник, который поможет вам получить технические условия для подключения к электроснабжению. Подскажу, как собрать документы, подать заявку и пройти все этапы подключения.`,
  },
  {
    route: 'heatsys',
    apiUrl: 'https://heatsys.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – помощник в вопросах подключения к системам теплоснабжения. Подскажу, как получить технические условия, оформить документы и пройти все этапы подключения.`,
  },
  {
    route: 'gassup',
    apiUrl: 'https://gassup.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш проводник в процессе подключения к газоснабжению. Помогу понять этапы процесса, собрать необходимые документы и подать заявку.`,
  },
  {
    route: 'watersup',
    apiUrl: 'https://watersup.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – цифровой помощник для подключения к системам водоснабжения и водоотведения. Объясню, как получить технические условия и оформить необходимые документы.`,
  },
  {
    route: 'landrules',
    apiUrl: 'https://landrules.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах изменения целевого назначения земельного участка. Подскажу, как подготовить документы и пройти процедуру в соответствии с законодательством.`,
  },
  {
    route: 'business-protection',
    apiUrl: 'https://business-prot.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах защиты бизнеса. Подскажу, как защитить бизнес от неправомерных действий в соответствии с законодательством.`,
  },
  {
    route: 'business-plan',
    apiUrl: 'https://business-plan.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах создания бизнес-плана. Я здесь, чтобы помочь вам с информацией по созданию соответствующего документа на основе лучших практик, и готов предоставить шаблоны под ваш запрос — от открытия магазина до создания детского сада.`,
  },
  {
    route: 'trademark',
    apiUrl: 'https://trademark.metatable.ai/create_message',
    promptMessage: `Добрый день!<br/>
      Я – ваш помощник в вопросах регистрации товарного знака. Подскажу, как пройти все процедуры и подать заявку в соответствии с законодательством.`,
  },
];
