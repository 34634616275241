import React from 'react';
import { Card } from 'antd';
import { useUnit } from 'effector-react';
import {
  $currentMeasure,
  $measureMode,
} from '../../../models/tezberModel/index.js';

function MeasurePopup() {
  const measureMode = useUnit($measureMode);
  const currentMeasure = useUnit($currentMeasure);

  if (!measureMode) {
    return null;
  }
  return (
    <Card
      style={{
        position: 'absolute',
        top: 20,
        left: 62,
        padding: 8,
      }}
    >
      Расстояние {currentMeasure}
    </Card>
  );
}

export default MeasurePopup;
