import React, { useEffect, useState } from 'react';
import { Button, Card, Input, List, Tooltip, Tree } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './PlacesSearch.module.css';
import { $isDarkTheme, $language } from '../../../models/authModel/index.js';
import {
  $checkedPlaces,
  $tezberListData,
  changeCheckedPlacesEv,
  clearBySearchEv,
  clearPlacesEv,
  invertPlacesEv,
  selectAllPlacesEv,
} from '../../../models/tezberModel/index.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { flyTo } from '../../../utils/mapbox-utils.js';

const filterPlaces = (search, places) => {
  return places.filter((place) =>
    place.toLowerCase().includes(search.toLowerCase())
  );
};

function PlacesSearch() {
  const isDark = useUnit($isDarkTheme);
  const [search, setSearch] = useState(undefined);
  const data = useUnit($tezberListData);
  const checkedPlaces = useUnit($checkedPlaces);
  const language = useUnit($language);
  const selectAllPlaces = useUnit(selectAllPlacesEv);
  const clearPlaces = useUnit(clearPlacesEv);
  const invertPlaces = useUnit(invertPlacesEv);
  const clearBySearch = useUnit(clearBySearchEv);

  const onCheck = (nodes, e) => {
    changeCheckedPlacesEv(nodes);
    if (e.checked) {
      flyTo(Object.values(e.node.center.coordinates), 9);
    }
  };

  const onSearch = (e) => {
    clearBySearch();
    setSearch(e.target.value);
  };

  const generateTreeData = () => {
    return data.filter((item) => {
      return (
        item.title.includes(search) ||
        item?.children?.some((child) => child.title.includes(search))
      );
    });
  };

  return (
    <Card
      style={{
        height: '100%',
        overflow: 'scroll',
        position: 'relative',
      }}
      styles={{
        body: {
          padding: '24px',
        },
      }}
    >
      {/* <div */}
      {/*  style={{ background: isDark ? '#1E2328' : 'white' }} */}
      {/*  className={style.search_places} */}
      {/* > */}
      {/*  <Input */}
      {/*    prefix={<SearchOutlined />} */}
      {/*    placeholder="Поиск..." */}
      {/*    onChange={(e) => setSearch(e.target.value)} */}
      {/*    value={search} */}
      {/*  /> */}
      {/* </div> */}
      <div className={style.controls_wrapper}>
        <Tooltip title={ru_en_page_dictionary.places_select_all[language]}>
          <Button onClick={selectAllPlaces} className={style.controls_button}>
            {ru_en_page_dictionary.places_select_all[language]}
          </Button>
        </Tooltip>
        <Tooltip title={ru_en_page_dictionary.places_clear[language]}>
          <Button onClick={clearPlaces} className={style.controls_button}>
            {ru_en_page_dictionary.places_clear[language]}
          </Button>
        </Tooltip>
        <Tooltip title={ru_en_page_dictionary.places_invert[language]}>
          <Button onClick={invertPlaces} className={style.controls_button}>
            {ru_en_page_dictionary.places_invert[language]}
          </Button>
        </Tooltip>
      </div>
      {/* FIXME 30.12 Hide for now by Serega */}
      {/* <Input */}
      {/*  style={{ */}
      {/*    marginBottom: 8, */}
      {/*  }} */}
      {/*  value={search} */}
      {/*  onChange={onSearch} */}
      {/*  placeholder={ru_en_page_dictionary.tezber_search[language]} */}
      {/* /> */}
      <Tree
        treeData={search ? generateTreeData() : data}
        checkable
        // showLine
        showIcon
        multiple
        selectable={false}
        onCheck={onCheck}
        checkedKeys={checkedPlaces}
      />
      {/* <List */}
      {/*  dataSource={search ? filterPlaces(search, data) : data} */}
      {/*  renderItem={(item) => ( */}
      {/*    <List.Item */}
      {/*      className={style.place_item} */}
      {/*      onClick={() => console.log(item)} */}
      {/*    > */}
      {/*      {item} */}
      {/*    </List.Item> */}
      {/*  )} */}
      {/* /> */}
    </Card>
  );
}

export default PlacesSearch;
