import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useUnit } from 'effector-react';
import Header from './sections/Header.jsx';
import Footer from './sections/Footer.jsx';
import HeaderSmallMenu from './sections/HeaderSmallMenu.jsx';
import { lightTheme } from '../../themeConfigs/themeConfigs.js';
import { $authStatus, setAuthStatusEv } from '../../models/authModel/index.js';
import { cookies } from '../../api/axiosinstance.js';
import { initPublicWsEv } from '../../models/webSocketModel/index.js';

function RbpLanding() {
  const [offset, setOffset] = useState(0);
  const authStatus = useUnit($authStatus);
  const location = useLocation();
  const navigate = useNavigate();
  const setAuth = useUnit(setAuthStatusEv);
  const initPublicWs = useUnit(initPublicWsEv);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // useEffect(() => {
  //   if (!authStatus && location.pathname === '/payment') {
  //     const token = cookies.get('access_token');
  //     if (!token) {
  //       navigate('/login');
  //     } else {
  //       setAuth(true);
  //     }
  //   }
  // }, [authStatus, location]);

  useEffect(() => {
    if (!window.wsp) {
      initPublicWsEv();
    }
  }, []);

  return (
    <ConfigProvider theme={lightTheme}>
      <div style={{ background: '#FFFFFF', position: 'relative' }}>
        <Header offset={offset} />
        <HeaderSmallMenu />
        <Outlet />
        <Footer />
      </div>
    </ConfigProvider>
  );
}

export default RbpLanding;
