import booleanIntersects from '@turf/boolean-intersects';

export const layerColors = {
  janaPost: '#FF5733',
  ozon: '#4CAF50',
  kimigo: '#1ABC9C',
  express: '#F39C12',
  wildberries: '#9B59B6',
  cdek: '#E74C3C',
};

export const calcChartData = (dots) => {
  return Object.fromEntries(
    Object.keys(dots).map((item) => {
      return [
        item,
        {
          pop_total: dots[item].reduce((a, b) => a + b.properties.pop_total, 0),
          pop_18_60: dots[item].reduce((a, b) => a + b.properties.pop_18_60, 0),
        },
      ];
    })
  );
};

export const formTableData = (dots) => {
  return dots.map((item, index) => ({
    ...item.properties,
    key: item.properties.id || item.properties.name,
    coordinates: item.geometry.coordinates,
  }));
};

export const mutateHexagons = (hexagons, node) => {
  return hexagons.map((item) => {
    return {
      ...item,
      properties: {
        ...item.properties,
        index_main:
          node === 'active'
            ? item.properties.pop_18_60
            : item.properties.pop_total,
      },
    };
  });
};

export const filterDots = (dots, places) => {
  return dots.filter((item) => {
    return (
      places.includes(item.properties.region) ||
      places.includes(item.properties.settlement)
    );
  });
};

export const filterDotsByHexagons = (dots, hexagons) => {
  return dots.filter((dot) => {
    return hexagons.some((hex) => booleanIntersects(dot, hex));
  });
};

const columns_dict = {
  name: 'Название ПВЗ',
  pop_18_60: 'Охват ЦА, чел',
  pop_total: 'Охват общий, чел',
  GRAV_pop_18_60: 'Грав. модель ЦА',
  GRAV_pop_total: 'Грав. модель общий',
  pvz_area: 'Площадь',
  owners: 'Владелец',
  region: 'Область',
  settlement: 'Населенный пункт',
  full_address: 'Адрес',
};

const order = Object.keys(columns_dict);

export const formColumns = (obj, addSortAndFilter) => {
  if (!obj) return [];
  return Object.keys(obj)
    .filter((item) => item !== 'key' && item !== 'coordinates' && item !== 'id')
    .map((item) => {
      return {
        title: columns_dict[item],
        dataIndex: item,
        key: `table_${item}`,
        ...addSortAndFilter(item),
        place: order.indexOf(item),
      };
    })
    .sort((a, b) => a.place - b.place)
    .map((item) => {
      delete item.place;
      return item;
    });
};

export const staticGradient = {
  active: [0, 50, 100, 200, 500, 1000, 1500, 2500, 3500, 5000, Infinity],
  total: [0, 100, 300, 500, 1000, 2000, 3000, 5000, 7000, 10000, Infinity],
};
