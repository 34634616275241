import { createEvent } from 'effector';

export const putZoom7HexagonsEv = createEvent();

export const setChosenZoom7HexagonEv = createEvent();
export const resetChosenZoom7HexagonEv = createEvent();

export const changePublicBusinessTypeEv = createEvent()

export const recalculatePublicCardsEv = createEvent()

export const calculateIndexCardsByHexEv = createEvent()

export const manageGradientEv = createEvent()
export const setRegularGradientEv = createEvent()
export const setRangeGradientEv = createEvent()

export const setClickCoordsEv = createEvent()
export const resetClickCoordsEv = createEvent()

export const initGradientByPublicEv = createEvent()
