import { sample } from 'effector';
import notification from 'antd/es/notification';
import mapboxgl from 'mapbox-gl';
import {
  $chosenPoint,
  $isError,
  $isSuccess,
  $language,
  $menuModal,
  $secondGradient,
} from './stores.js';
import {
  changeLanguageEv,
  changeMenuModalEv,
  changeSecondGradientEv,
  openPaymentUrlEv,
  resetErrorEv,
  resetSuccessEv,
  sendPaymentEv,
  sendRegRequestEv,
  setChosenPointEv,
} from './events.js';
import { sendRegRequestFx } from './effects.js';
import { wsSendPayment } from '../../utils/webSocketConfig.js';

$language.on(changeLanguageEv, (_, payload) => payload);

$isSuccess.reset(resetSuccessEv);
$isError.reset(resetErrorEv);

$menuModal.on(changeMenuModalEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

$secondGradient.on(changeSecondGradientEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

sample({
  clock: sendRegRequestEv,
  target: sendRegRequestFx,
});

// sample({
//   clock: sendRegRequestFx.doneData,
//   fn: () => true,
//   target: $isSuccess,
// });
//
// sample({
//   clock: sendRegRequestFx.failData,
//   fn: () => true,
//   target: $isError,
// });

sendRegRequestFx.doneData.watch((data) => {
  notification.success({
    message: 'Успех!',
    description: 'Ваша заявка отправлена на рассмотрение',
    placement: 'topRight',
  });
});

sendRegRequestFx.failData.watch((data) => {
  notification.error({
    message: 'Ошибка!',
    description: 'Что-то пошло не так...',
    placement: 'topRight',
  });
});

sample({
  source: $chosenPoint,
  clock: setChosenPointEv,
  fn: (source, clock) => {
    if (source) {
      source.remove();
    }
    return new mapboxgl.Marker()
      .setLngLat([clock.lng, clock.lat])
      .addTo(window.map);
  },
  target: $chosenPoint,
});

sample({
  clock: sendPaymentEv,
  fn: (clock) => {
    wsSendPayment({
      email: clock.email,
      amount: clock.package.price,
    });
  },
});

sample({
  clock: openPaymentUrlEv,
  fn: (clock) => {
    window.open(clock, 'newwindow', 'width=1200, height=800');
  },
});
