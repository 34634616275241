import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Card, Form, Input, Radio, Select, TreeSelect } from 'antd';
import style from '../RbpLanding.module.css';
import {
  $chosenPoint,
  $language,
  sendPaymentEv,
} from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import payments_city from '../../../assets/RbpLandingImages/Payments/payments_city.svg';
import MapBlock from '../../../components/MapBlock/MapBlock.jsx';
import payment_business_types from '../../../data/payment_business_types.json';
import { flyTo } from '../../../utils/mapbox-utils.js';
import useWindowSize from '../../../hooks/useWindowSize.js';
import { addSeparators } from '../../../utils/format-utils.js';

const packages = [
  {
    title: {
      ru: 'Одна локация',
      en: 'One location',
    },
    price: 14500,
  },
  {
    title: {
      ru: 'Три локации',
      en: 'Three locations',
    },
    price: 36000,
    discount: 17,
  },
  {
    title: {
      ru: 'Пять локаций',
      en: 'Five locations',
    },
    price: 50000,
    discount: 31,
  },
  {
    title: {
      ru: 'Десять локаций',
      en: 'Ten locations',
    },
    price: 90000,
    discount: 38,
  },
];

function Payment() {
  const language = useUnit($language);
  const [form] = Form.useForm();
  const [chosenPackage, setChosenPackage] = useState(1);
  const [treeExpanded, setTreeExpanded] = useState([]);
  const chosenPoint = useUnit($chosenPoint);
  const [width, height] = useWindowSize();
  const sendPayment = useUnit(sendPaymentEv);

  const onSelect = (value) => {
    if (treeExpanded.includes(value)) {
      setTreeExpanded([...treeExpanded].filter((item) => item !== value));
    } else {
      setTreeExpanded([...treeExpanded, value]);
    }
  };

  const business_types = payment_business_types.map((item) => {
    return {
      title: (
        <span
          style={{
            color: 'black',
            cursor: 'pointer',
            width: '100%',
            display: 'block',
          }}
          onClick={() => {
            onSelect(item.type);
          }}
        >
          {item.type}
        </span>
      ),
      value: item.type,
      disabled: true,
      children: item.subcategories.map((sub) => {
        return {
          value: sub.uuid,
          title: sub.subtype,
        };
      }),
    };
  });

  const onFinish = (values) => {
    sendPayment({
      ...values,
      package: packages[chosenPackage - 1],
    });
  };

  const zoomToClick = () => {
    flyTo([chosenPoint._lngLat.lng, chosenPoint._lngLat.lat], 13);
  };

  return (
    <div className={style.payments_wrapper}>
      <div className={style.container}>
        <div className={style.payments}>
          <div className={style.payments_form}>
            {/* <div className={style.section_name}> */}
            {/*  {rbp_landing_translation[language].payments_section} */}
            {/* </div> */}
            <div
              className={[style.section_title, style.section_title_small].join(
                ' '
              )}
            >
              {rbp_landing_translation[language].payments_title}
            </div>
            <div className={style.payments_address}>
              {/* <img src={payments_city} /> */}
              {/* <div> */}
              {/*  <div className={style.payments_address_title}> */}
              {/*    {rbp_landing_translation[language].payments_chosen_address}: */}
              {/*  </div> */}
              {/*  <div className={style.payments_address_value}> */}
              {/*    г. Алматы, ул. Желтоксан 87 */}
              {/*  </div> */}
              {/* </div> */}
              <Button
                type="primary"
                className={style.payments_address_button}
                disabled={!chosenPoint}
                onClick={zoomToClick}
              >
                Проверить адрес
              </Button>
            </div>
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                label={
                  <label className={style.payments_form_label}>
                    {
                      rbp_landing_translation[language]
                        .payments_form_business_type
                    }
                    :
                  </label>
                }
                name="business_type"
              >
                <TreeSelect
                  className={style.payments_form_input}
                  treeData={business_types}
                  onTreeExpand={(keys) => setTreeExpanded(keys)}
                  treeExpandedKeys={treeExpanded}
                />
              </Form.Item>
              <Form.Item
                style={{
                  marginBottom: 8,
                }}
                label={
                  <label className={style.payments_form_label}>
                    {rbp_landing_translation[language].payments_form_email}:
                  </label>
                }
                name="email"
              >
                <Input
                  placeholder="email@email.com"
                  className={style.payments_form_input}
                />
              </Form.Item>
              <div className={style.payments_form_note}>
                {rbp_landing_translation[language].payments_form_email_note}
              </div>
              <div style={{ marginTop: 20 }}>
                <label className={style.payments_form_label}>
                  {rbp_landing_translation[language].payments_form_package}:
                </label>
                <div className={style.packages_wrapper}>
                  {packages.map((item, index) => (
                    <div
                      key={`package_${index}`}
                      className={[
                        style.payments_form_package,
                        chosenPackage === index + 1 ? style.package_active : '',
                      ].join(' ')}
                      onClick={() => setChosenPackage(index + 1)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Radio
                          value={index + 1}
                          checked={chosenPackage === index + 1}
                        />
                        <div className={style.payments_package_title}>
                          {item.title[language]}
                        </div>
                        {item.discount && (
                          <div className={style.payments_package_discount}>
                            -{item.discount}%
                          </div>
                        )}
                      </div>
                      <div
                        className={style.payments_package_price}
                        style={{
                          color:
                            chosenPackage === index + 1 ? '#11023B' : '#828282',
                          marginTop:
                            width <= 1200
                              ? item.discount
                                ? '10px'
                                : '8px'
                              : '15px',
                        }}
                      >
                        {addSeparators(item.price)}₸
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                className={style.payments_form_button}
                onClick={form.submit}
              >
                {rbp_landing_translation[language].payments_form_button}
              </Button>
            </Form>
            <div className={style.payments_note}>
              * При приобретении мульти-пакета, указать новые локации можно в
              личном кабинете RBP после перехода по ссылке из письма,
              полученного после проведения оплаты.
            </div>
          </div>
          <Card
            style={{ height: 'unset' }}
            className="map_card"
            bodyStyle={{ height: '100%' }}
          >
            <MapBlock />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Payment;
