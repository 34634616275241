export const rbp_landing_translation = {
  ru: {
    menu_item_1: 'Возможности',
    menu_item_2: 'Принцип работы',
    menu_item_3: 'Платформа',
    menu_item_4: 'Стоимость',
    menu_item_5: 'FAQ',
    menu_item_6: 'Контакты',

    promo_title:
      'успех Вашего бизнеса на основе данных и объективной аналитике',
    promo_subtitle:
      'Уникальный на рынке аналитический сервис для оценки и выбора наилучших для Вашего бизнеса локаций с учетом спроса, конкуренции и потенциала роста',
    promo_button: 'Попробовать',

    features_section: 'Возможности',
    features_title: 'Почему Resilient Business Platform (RBP)?',
    features_subtitle:
      'Выберите подходящую вашим бизнес-потребностям продукцию',
    features_card_1_title: 'Оптимизация выбора локаций',
    features_card_1_desc:
      'Сократите время и затраты, получая интеллектуальные рекомендации по лучшим местам для Вашего бизнеса.',
    features_card_2_title: 'Подбор предложений об аренде',
    features_card_2_desc:
      'Получите доступ к актуальной информации об объектах недвижимости в выбранной локации для быстрой аренды подходящего помещения.',
    features_card_3_title: 'Анализ конкурентной среды',
    features_card_3_desc:
      'Выявляйте районы с высоким конкурентным преимуществом и оценивайте конкурентов по ключевым метрикам.',
    features_card_4_title: 'Эффективное планирование роста',
    features_card_4_desc:
      'Используйте данные для стратегического планирования, чтобы уверенно развивать бизнес и минимизировать финансовые риски.',

    how_it_works_section: 'Принцип работы',
    how_it_works_title: 'Как это работает?',
    how_it_works_subtitle:
      'Всего несколько шагов для принятия решения, основанного на данных RBP Lite',
    how_it_works_card_1_title: 'Выбираете локацию на карте',
    how_it_works_card_1_desc:
      'Укажите на карте точку, по которой необходимо получить детальную информацию. Мы предоставим характеристики локации в 10-минутном охвате от нее.',
    how_it_works_card_2_title: 'Указываете тип вашего бизнеса',
    how_it_works_card_2_desc:
      'Исходя из выбранного типа, в характеристику локации будут включены детализированные данные конкретно для него: конкурентное окружение, клиентские охваты',
    how_it_works_card_3_title:
      'Выберите пакет с необходимым Вам количеством отчетов',
    how_it_works_card_3_desc:
      'Исходя из выбранного типа, в характеристику локации будут включены детализированные данные конкретно для него: конкурентное окружение, клиентские охваты',
    how_it_works_card_4_title:
      'Получите на указанную почту доступ к платформе RBP Lite',
    how_it_works_card_4_desc:
      'После получения доступа Вам предоставляется полная аналитика локации и ее зоны охвата в цифровом интерактивном формате с возможностью выгрузить данные в формат Ecxel. А все анализируемы локации и их характеристики доступны Вам в личном кабинете.',

    openmap_title: 'Оцените потенциал Вашего бизнеса!',
    openmap_desc:
      'Начните анализ локаций, используя уже подготовленные данные на нашей открытой интерактивной карте. ',
    openmap_button: 'Открыть платформу',

    prices_section: 'Стоимость',
    prices_title: 'Получить доступ',
    prices_subtitle:
      'Выберите продукт для Вашего бизнеса и получите детальный профиль локации для принятия верных решений.',
    prices_card_1_desc: 'Оцените бесплатные инструменты',
    prices_card_2_desc: 'Анализ для вашего бизнеса',
    prices_card_3_desc: 'Все возможные данные по локации',

    prices_audience_feature_1: 'Общие демографические данные',
    prices_audience_feature_2: 'Относительная оценка дохода',
    prices_audience_feature_3:
      'Детальная характеристика пол-возраст-доход для работающих и проживающих в локации',
    prices_audience_feature_4: 'Охват с учетом конкурентного поля',
    prices_audience_feature_5: 'Функционал RBP Lite',
    prices_audience_feature_6: 'Выделение целевых аудиторий',

    prices_business_feature_1:
      'Количество конкурентов по одному из 28 видов бизнеса',
    prices_business_feature_2: 'Средняя стоимость аренды',
    prices_business_feature_3:
      'Количество и перечень конкурентов в выбранном виде бизнеса с детализацией до типов',
    prices_business_feature_4:
      'Объемы трат в локации: общие по выбранному виду бизнеса',
    prices_business_feature_5:
      'Актуальный список доступных в локации предложений аренды',
    prices_business_feature_6: 'Функционал RBP Lite',
    prices_business_feature_7:
      'Разработка моделей прогнозирования дохода в зависимости от выбранной локации индивидуально под запрос Клиента',

    prices_traffic_feature_1:
      'Относительные и косвенные характеристики доступности и проходимости территории',
    prices_traffic_feature_2:
      'Траффик в абсолютных значениях через территорию и по участкам улиц',
    prices_traffic_feature_3: 'Функционал RBP Lite',
    prices_traffic_feature_4:
      'Кастомизация и разработка сервисов для решения логистических задач',

    prices_audience: 'Аудитория',
    prices_business: 'Бизнес',
    prices_traffic: 'Доступность и трафик',

    prices_free: 'Бесплатно',
    prices_on_request: 'По запросу',
    prices_try: 'Попробовать',
    prices_goto: 'Перейти',
    prices_request: 'Отправить запрос',
    prices_popular_tag: 'Популярный',
    prices_report: '/за локацию',
    prices_download: 'Посмотреть пример',

    partners_section: 'Клиенты',
    partners_title: 'Наши партнеры',

    faq_section: 'FAQ',
    faq_title: 'Частые вопросы',
    faq_question_1_title: 'Какие данные используются для анализа?',
    faq_question_1_items:
      'Мы используем данные из различных источников, включая данные операторов фискальных данных (ОФД) для оценки местной экономической активности, данные мобильных операторов для анализа социально-демографических характеристик населения, данные агрегаторов пространственных данных и спутниковые снимки для расчета показателей городской среды. Это позволяет платформе предоставлять точные рекомендации по выбору места для бизнеса и анализировать потенциальный спрос.',

    faq_question_2_title: 'Как часто обновляются данные на платформе?',
    faq_question_2_items:
      'Данные на платформе обновляются регулярно, чтобы вы могли использовать наиболее актуальную информацию для принятия решений. Частота обновлений зависит от источника данных, часть информации собирается ежедневно.',

    faq_question_3_title: 'Какие инсайты можно получить из данных?',
    faq_question_3_items:
      'Для бизнеса платформа предлагает данные о рыночном потенциале и возможностях, основанных на местоположении. Например, ритейлеры могут использовать геопространственную аналитику для оптимизации расположения магазинов на основе пешеходных потоков или демографических тенденций. Девелоперы — оценивать потенциал роста в разных районах, принимая во внимание инфраструктуру, зонирование и рыночный спрос. Данные также помогают бизнесам адаптироваться к изменяющимся условиям в городе и оставаться конкурентоспособными.',

    faq_question_4_title: 'Какие типы бизнеса поддерживает платформа?',
    faq_question_4_items:
      'Платформа Resilient Business Platform Light поддерживает почти 30 типов бизнеса, включая продуктовый и непродуктовый ритейл, кафе и рестораны, ремонт бытовой техники, салоны красоты и многие другие. Мы предлагаем индивидуальные рекомендации, основанные на типе вашего бизнеса и его потребностях.',

    faq_question_5_title: 'Какие бизнесы могут быть заинтересованы?',
    faq_question_5_items:
      'Для городских администраций платформа предоставляет важную информацию о городской жизнестойкости, работе инфраструктуры и демографической динамике. Городские управленцы могут оценивать уязвимость к чрезвычайным ситуациям, эффективность транспортной системы  и распределении ресурсов, что способствует более умному городскому развитию и проактивному управлению рисками. Администрации также получат возможность отслеживать долгосрочные тенденции, такие как рост населения или экономическое развитие, что помогает принимать решения на основе данных, учитывающие как текущие, так и будущие вызовы.\n' +
      'Для бизнеса платформа предлагает данные о рыночном потенциале, поведении клиентов и возможностях, основанных на местоположении. Например, ритейлеры могут использовать геопространственную аналитику для оптимизации расположения магазинов на основе пешеходных потоков или демографических тенденций. Девелоперы — оценивать потенциал роста в разных районах, принимая во внимание инфраструктуру, зонирование и рыночный спрос. Данные также помогают бизнесам адаптироваться к изменяющимся условиям в городе и оставаться конкурентоспособными.',

    faq_question_6_title: 'Какие бизнесы могут быть заинтересованы?',
    faq_question_6_items:
      'Resilient Business Platform может быть полезна множеству отраслей, где принятие решений основывается на городских данных. Так, например, ритейлеры могут анализировать потребительские тенденции и оптимизировать расположение магазинов на основе геопространственных данных. Resilient Business Platform поможет этим компаниям лучше адаптироваться к городским вызовам и возможностям и обеспечить устойчивый рост и жизнестойкость.',

    faq_question_7_title:
      'Могу ли я использовать платформу, если у меня небольшой бизнес с ограниченным бюджетом?',
    faq_question_7_items:
      'Да, Resilient Business Platform Light разработана специально для малого и среднего бизнеса с учетом доступности и простоты использования. Платформа предлагает необходимый минимум аналитики для принятия ключевых решений без лишних затрат.',

    faq_question_8_title:
      'Что делать, если я планирую открыть несколько точек в одном городе?',
    faq_question_8_items:
      'Платформа поможет вам оценить наиболее выгодные районы для расширения сети, используя гравитационные модели. Эти модели учитывают концентрацию конкурентов, трафик и плотность населения, что позволяет точно определить зоны с наибольшим потенциалом для вашего бизнеса. Вы сможете оптимально распределить ресурсы и охватить больше клиентов, открывая новые точки в местах с максимальной привлекательностью и минимальной конкуренцией.',

    faq_question_9_title:
      'Нас интересует поиск лучших мест для наших магазинов. Насколько детализированы данные, которые предоставляет ваша компания?',
    faq_question_9_items:
      'Наша платформа предоставляет детализированные данные о конкретных городских локациях, используя различные источники, включая конкурентный анализ и характеристику целевых аудиторий. Эти аналитические данные помогают принимать обоснованные решения.  \n' +
      'Например, если вы изучаете коммерческие локации, мы можем предоставить подробную информацию о трафике, пиковых периодах активности и типах бизнеса, привлекающих наибольшее количество посетителей. \n' +
      'Данные агрегируются на гексагональную ячейку со стороной 200 метров или на граф улично-дорожной сети, что позволяет детально изучить территорию на крупном масштабе. \n' +
      'Эти данные помогут ритейлерам определить лучшие места для расширения существующей сети или оптимизировать свои услуги на основе поведения клиентов.',

    faq_question_10_title:
      'Как выглядит отчет по профилю локации и как он генерируется?',
    faq_question_10_items:
      'Наша платформа предлагает функцию создания аналитического профиля локации с полностью настраиваемой структурой. Пользователи могут формировать отчеты, адаптированные под их потребности по выбранной локации. Вы можете определить наиболее релевантные индикаторы, в таких направлениях как экономическая активность, характеристика целевых аудиторий или оценка проходимости.',

    contacts_section: 'Контакты',
    contacts_title: 'Остались вопросы?',
    contacts_subtitle:
      'Свяжитесь с нами или оставьте свой вопрос заполнив форму, и мы поможем вам узнать больше о возможностях платформы Resilient Business Platform.',
    contacts_address: 'Адрес',
    contacts_address_value: 'Алматы, ул. Желтоксан 87, офис 25',
    contacts_phone: 'Телефон',
    contacts_form_name: 'Ваше имя',
    contacts_form_name_placeholder: 'Имя и фамилия',
    contacts_form_company: 'Компания',
    contacts_form_company_placeholder: 'Официальное название',
    contacts_form_phone: 'Телефон',
    contacts_form_message: 'Сообщение',
    contacts_form_message_placeholder: 'Вопрос или предложение',
    contacts_form_button: 'Отправить',
    contacts_form_name_error: 'Пожалуйста, введите имя',
    contacts_form_phone_error: 'Пожалуйста, введите номер телефона',
    contacts_form_email_error: 'Пожалуйста, введите электронную почту',
    contacts_form_email_wrong_format:
      'Пожалуйста, введите корректную электронную почту',

    footer_title_1: 'Офис',
    footer_description_1: 'Алматы, ул. Желтоксан 87, офис 25',
    footer_description_2: 'Телефон: +7 706 640 55 95',
    footer_description_3: 'Email: info@qala-ai.com',
    footer_title_2: 'Часы работы',
    footer_description_4: 'Понедельник – пятница',
    footer_description_5: 'С 9:00 до 18:00',
    footer_description_6: 'Суббота и воскресенье – выходной',
    footer_title_3: 'Помощь',
    footer_description_7: 'Публичная оферта',
    footer_description_8: 'Пользовательское соглашение',
    footer_subtitle: '©2024 Qala-ai.com',

    payments_section: 'Покупка',
    payments_title: 'Отчет о локации',
    payments_chosen_address: 'Выбранный адрес',
    payments_form_business_type: 'Тип бизнеса',
    payments_form_email: 'Почта',
    payments_form_email_note:
      'На данный адрес будут отправлены данные для получения отчета',
    payments_form_package: 'Выберите пакет',
    payments_form_button: 'Купить отчет',

    payments_reports_section: 'Данные анализа',
    payments_reports_video_title: 'Что вы получите в RBP?',
    payments_reports_title: 'Что будет в отчете?',
    payments_reports_desc_1:
      'Отчет содержит данные о населении, домохозяйствах, транспортной и пешеходной доступности, а также конкуренции. ',
    payments_reports_desc_2:
      'Полный список параметров, которые анализирует наш сервис:',
    payments_reports_params_1:
      'Половозрастная характеристика с выделением возрастных групп для проживающего и работающего в локации населения',
    payments_reports_params_2: 'Число домохозяйств',
    payments_reports_params_3:
      'Уровень дохода проживающего и работающего в локации населения',
    payments_reports_params_4: 'Количество конкурентов и их список',
    payments_reports_params_5:
      'Объем трат в локации общий и по типу выбранного бизнесов',
    payments_reports_params_6:
      'Стоимости аренды коммерческой недвижимости и актуальные предложения',
    payments_reports_params_7: 'Трафик через локацию с проходимостью по улицам',
    payments_reports_params_8: 'Транспортная доступность локации и др.',
    payments_reports_download: 'Скачать пример отчета',
  },
  en: {
    menu_item_1: 'En_Placeholder',
    menu_item_2: 'En_Placeholder',
    menu_item_3: 'En_Placeholder',
    menu_item_4: 'En_Placeholder',
    menu_item_5: 'En_Placeholder',
    menu_item_6: 'En_Placeholder',
    promo_title: 'En_Placeholder',
    promo_subtitle: 'En_Placeholder',
    promo_button: 'En_Placeholder',
    features_section: 'En_Placeholder',
    features_title: 'En_Placeholder',
    features_subtitle: 'En_Placeholder',
    features_card_1_title: 'En_Placeholder',
    features_card_1_desc: 'En_Placeholder',
    features_card_2_title: 'En_Placeholder',
    features_card_2_desc: 'En_Placeholder',
    features_card_3_title: 'En_Placeholder',
    features_card_3_desc: 'En_Placeholder',
    features_card_4_title: 'En_Placeholder',
    features_card_4_desc: 'En_Placeholder',
    how_it_works_section: 'En_Placeholder',
    how_it_works_title: 'En_Placeholder',
    how_it_works_subtitle: 'En_Placeholder',
    how_it_works_card_1_title: 'En_Placeholder',
    how_it_works_card_1_desc: 'En_Placeholder',
    how_it_works_card_2_title: 'En_Placeholder',
    how_it_works_card_2_desc: 'En_Placeholder',
    how_it_works_card_3_title: 'En_Placeholder',
    how_it_works_card_3_desc: 'En_Placeholder',
    how_it_works_card_4_title: 'En_Placeholder',
    how_it_works_card_4_desc: 'En_Placeholder',
    openmap_title: 'En_Placeholder',
    openmap_desc: 'En_Placeholder',
    openmap_button: 'En_Placeholder',
    prices_section: 'En_Placeholder',
    prices_title: 'En_Placeholder',
    prices_subtitle: 'En_Placeholder',
    prices_card_1_desc: 'En_Placeholder',
    prices_card_2_desc: 'En_Placeholder',
    prices_card_3_desc: 'En_Placeholder',
    prices_audience_feature_1: 'En_Placeholder',
    prices_audience_feature_2: 'En_Placeholder',
    prices_audience_feature_3: 'En_Placeholder',
    prices_audience_feature_4: 'En_Placeholder',
    prices_audience_feature_5: 'En_Placeholder',
    prices_audience_feature_6: 'En_Placeholder',
    prices_business_feature_1: 'En_Placeholder',
    prices_business_feature_2: 'En_Placeholder',
    prices_business_feature_3: 'En_Placeholder',
    prices_business_feature_4: 'En_Placeholder',
    prices_business_feature_5: 'En_Placeholder',
    prices_business_feature_6: 'En_Placeholder',
    prices_business_feature_7: 'En_Placeholder',
    prices_traffic_feature_1: 'En_Placeholder',
    prices_traffic_feature_2: 'En_Placeholder',
    prices_traffic_feature_3: 'En_Placeholder',
    prices_traffic_feature_4: 'En_Placeholder',
    prices_audience: 'En_Placeholder',
    prices_business: 'En_Placeholder',
    prices_traffic: 'En_Placeholder',
    prices_free: 'En_Placeholder',
    prices_on_request: 'En_Placeholder',
    prices_try: 'En_Placeholder',
    prices_request: 'En_Placeholder',
    prices_popular_tag: 'En_Placeholder',
    prices_report: 'En_Placeholder',
    prices_download: 'En_Placeholder',
    partners_section: 'En_Placeholder',
    partners_title: 'En_Placeholder',
    faq_section: 'En_Placeholder',
    faq_title: 'En_Placeholder',
    faq_question_1_title: 'En_Placeholder',
    faq_question_1_items: 'En_Placeholder',
    faq_question_2_title: 'En_Placeholder',
    faq_question_2_items: 'En_Placeholder',
    faq_question_3_title: 'En_Placeholder',
    faq_question_3_items: 'En_Placeholder',
    faq_question_4_title: 'En_Placeholder',
    faq_question_4_items: 'En_Placeholder',
    faq_question_5_title: 'En_Placeholder',
    faq_question_5_items: 'En_Placeholder',
    faq_question_6_title: 'En_Placeholder',
    faq_question_6_items: 'En_Placeholder',
    faq_question_7_title: 'En_Placeholder',
    faq_question_7_items: 'En_Placeholder',
    faq_question_8_title: 'En_Placeholder',
    faq_question_8_items: 'En_Placeholder',
    faq_question_9_title: 'En_Placeholder',
    faq_question_9_items: 'En_Placeholder',
    faq_question_10_title: 'En_Placeholder',
    faq_question_10_items: 'En_Placeholder',
    contacts_section: 'En_Placeholder',
    contacts_title: 'En_Placeholder',
    contacts_subtitle: 'En_Placeholder',
    contacts_address: 'En_Placeholder',
    contacts_address_value: 'En_Placeholder',
    contacts_phone: 'En_Placeholder',
    contacts_form_name: 'En_Placeholder',
    contacts_form_name_placeholder: 'En_Placeholder',
    contacts_form_company: 'En_Placeholder',
    contacts_form_company_placeholder: 'En_Placeholder',
    contacts_form_phone: 'En_Placeholder',
    contacts_form_message: 'En_Placeholder',
    contacts_form_message_placeholder: 'En_Placeholder',
    contacts_form_button: 'En_Placeholder',
    contacts_form_name_error: 'En_Placeholder',
    contacts_form_phone_error: 'En_Placeholder',
    contacts_form_email_error: 'En_Placeholder',
    contacts_form_email_wrong_format: 'En_Placeholder',
    footer_title_1: 'En_Placeholder',
    footer_description_1: 'En_Placeholder',
    footer_description_2: 'En_Placeholder',
    footer_description_3: 'En_Placeholder',
    footer_title_2: 'En_Placeholder',
    footer_description_4: 'En_Placeholder',
    footer_description_5: 'En_Placeholder',
    footer_description_6: 'En_Placeholder',
    footer_title_3: 'En_Placeholder',
    footer_description_7: 'En_Placeholder',
    footer_description_8: 'En_Placeholder',
    footer_subtitle: 'En_Placeholder',
    payments_section: 'En_Placeholder',
    payments_title: 'En_Placeholder',
    payments_chosen_address: 'En_Placeholder',
    payments_form_business_type: 'En_Placeholder',
    payments_form_email: 'En_Placeholder',
    payments_form_email_note: 'En_Placeholder',
    payments_form_package: 'En_Placeholder',
    payments_form_button: 'En_Placeholder',
    payments_reports_section: 'En_Placeholder',
    payments_reports_video_title: 'En_Placeholder',
    payments_reports_title: 'En_Placeholder',
    payments_reports_desc_1: 'En_Placeholder',
    payments_reports_desc_2: 'En_Placeholder',
    payments_reports_params_1: 'En_Placeholder',
    payments_reports_params_2: 'En_Placeholder',
    payments_reports_params_3: 'En_Placeholder',
    payments_reports_params_4: 'En_Placeholder',
    payments_reports_download: 'En_Placeholder',
  },
};
