import zoom9 from '../../data/zoom9.json';
import payment_business_types from '../../data/payment_business_types.json';
import { addSeparators } from '../../utils/format-utils.js';
import RBP_LIte_index_help from '../../dictionaries/RBP_Lite_dict.json';

export const formatDetailsData = (
  source,
  chosen_metric,
  d9Source,
  aggregates,
  language
) => {
  return chosen_metric.map((item) => {
    const regex = new RegExp(`${item}[a-z]`, 'g');
    const keys = Object.keys(source).filter((key) => key.match(regex));
    switch (item) {
      case 'd1': {
        const data = {};
        for (let i = 2; i <= 5; i++) {
          const separator = new RegExp(`d1[m|w]${i}`, 'g');
          const pair = keys.filter((key) => key.match(separator));
          data[i] = Object.fromEntries(
            pair.map((pairItem) => {
              return [
                pairItem.includes('m') ? 'm' : 'w',
                Math.round(source[pairItem]),
              ];
            })
          );
        }
        return {
          title: RBP_LIte_index_help['1'][`name_${language}`],
          type: 'columns',
          metric: 'd1',
          data,
          info: RBP_LIte_index_help['1'][`description_${language}`],
        };
      }
      case 'd6': {
        const data = {
          ' ': {},
        };
        ['mkd', 'izs'].forEach((key) => {
          if (source[`d6${key}`]) {
            data[' '][key] = Math.round(source[`d6${key}`]);
          }
        });

        return {
          title: RBP_LIte_index_help['6'][`name_${language}`],
          metric: 'd6',
          type: 'bar',
          data,
          info: RBP_LIte_index_help['6'][`description_${language}`],
        };
      }
      case 'd2': {
        const data = {};
        for (let i = 2; i <= 5; i++) {
          const separator = new RegExp(`d2[m|w]${i}`, 'g');
          const pair = keys.filter((key) => key.match(separator));
          data[i] = Object.fromEntries(
            pair.map((pairItem) => {
              return [
                pairItem.includes('m') ? 'm' : 'w',
                Math.round(source[pairItem]),
              ];
            })
          );
        }
        return {
          title: RBP_LIte_index_help['2'][`name_${language}`],
          type: 'columns',
          metric: 'd2',
          data,
          info: RBP_LIte_index_help['2'][`description_${language}`],
        };
      }
      case 'd3': {
        const data = {};
        for (let i = 1; i <= 4; i++) {
          const separator = new RegExp(`d3[m|w]${i}`, 'g');
          const pair = keys.filter((key) => key.match(separator));
          data[i] = Object.fromEntries(
            pair.map((pairItem) => {
              return [
                pairItem.includes('m') ? 'm' : 'w',
                Math.round(source[pairItem]),
              ];
            })
          );
        }
        return {
          title: RBP_LIte_index_help['3'][`name_${language}`],
          type: 'columns',
          metric: 'd3',
          data,
          info: RBP_LIte_index_help['3'][`description_${language}`],
        };
      }
      case 'd4': {
        const data = {};
        for (let i = 1; i <= 4; i++) {
          const separator = new RegExp(`d4[m|w]${i}`, 'g');
          const pair = keys.filter((key) => key.match(separator));
          data[i] = Object.fromEntries(
            pair.map((pairItem) => {
              return [
                pairItem.includes('m') ? 'm' : 'w',
                Math.round(source[pairItem]),
              ];
            })
          );
        }
        return {
          title: RBP_LIte_index_help['4'][`name_${language}`],
          type: 'columns',
          metric: 'd4',
          data,
          info: RBP_LIte_index_help['4'][`description_${language}`],
        };
      }
      case 'd7': {
        // FIXME Depends on preset

        const main = keys.filter((key) => /^d7t\d+$/g.test(key))[0];
        const chosen_category = payment_business_types.find((category) => {
          return category.subcategories.find((sub) =>
            sub.uuid.includes(main.replace('d7', ''))
          );
        });
        const sub = keys.filter((key) => /^d7t\d+pt\d+$/g.test(key));
        return {
          type: 'table',
          metric: 'd7',
          title: RBP_LIte_index_help['7'][`name_${language}`],
          data: [
            {
              business_type: chosen_category.type,
              total_cnt: source[main],
              city_avg: Math.round(aggregates.d7.mean),
            },
          ],
          columns: [
            {
              title: 'Тип бизнеса',
              dataIndex: 'business_type',
              key: 'business_type',
              width: '5%',
            },
            {
              title: 'Общее число ед',
              dataIndex: 'total_cnt',
              key: 'total_cnt',
              width: '5%',
              render: (value) => (value !== '-' ? addSeparators(value) : value),
            },
            {
              title: 'Среднее значение по городу, ед.',
              dataIndex: 'city_avg',
              key: 'city_avg',
              width: '5%',
              render: (value) => (value !== '-' ? addSeparators(value) : value),
            },
          ],
          subTable: {
            data: sub.map((sub) => {
              const uuid = sub.replace('d7', '');
              return {
                business_subtype: chosen_category.subcategories.find(
                  (category) => category.uuid === uuid
                ).subtype,
                total_cnt: source[sub],
                city_avg: Math.round(aggregates.d7.mean),
              };
            }),
            columns: [
              {
                title: 'Подтип бизнеса',
                dataIndex: 'business_subtype',
                key: 'business_subtype',
                width: '5%',
              },
              {
                title: 'Общее число ед',
                dataIndex: 'total_cnt',
                key: 'total_cnt',
                width: '5%',
                render: (value) =>
                  value !== '-' ? addSeparators(value) : value,
              },
              {
                title: 'Среднее значение по городу, ед.',
                dataIndex: 'city_avg',
                key: 'city_avg',
                width: '5%',
                render: (value) =>
                  value !== '-' ? addSeparators(value) : value,
              },
            ],
          },
          info: RBP_LIte_index_help['7'][`description_${language}`],
        };
      }
      case 'd8': {
        // FIXME Depends on preset
        const bt = keys[0].replace(/d8.{3}/g, '');
        return {
          title: RBP_LIte_index_help['8'][`name_${language}`],
          metric: 'd8',
          type: 'bar',
          bt,
          data: {
            ' ': {
              lv: Math.round(source[keys.find((elem) => elem.includes('lv'))]),
              wk: Math.round(source[keys.find((elem) => elem.includes('wk'))]),
            },
          },
          info: RBP_LIte_index_help['8'][`description_${language}`],
        };
      }
      case 'd10': {
        // FIXME Depends on preset
        const bt = keys[0].replace(/d10.{2}/g, '');
        return {
          title: RBP_LIte_index_help['10'][`name_${language}`],
          metric: 'd10',
          type: 'bar',
          data: {
            bd: {
              [bt]: Math.round(
                source[keys.find((elem) => elem.includes('bd'))]
              ),
            },
            bv: {
              [bt]: Math.round(
                source[keys.find((elem) => elem.includes('bv'))]
              ),
            },
            vd: {
              [bt]: Math.round(
                source[keys.find((elem) => elem.includes('vd'))]
              ),
            },
            vv: {
              [bt]: Math.round(
                source[keys.find((elem) => elem.includes('vv'))]
              ),
            },
          },
          info: RBP_LIte_index_help['10'][`description_${language}`],
        };
      }
      case 'd5': {
        return {
          title: RBP_LIte_index_help['5'][`name_${language}`],
          metric: 'd5',
          type: 'bar',
          data: {
            bd: {
              0: Math.round(source.d5bd0),
              1: Math.round(source.d5bv0),
              2: Math.round(source.d5vd0),
              3: Math.round(source.d5vv0),
            },
            bv: {
              0: Math.round(source.d5bd1),
              1: Math.round(source.d5bv1),
              2: Math.round(source.d5vd1),
              3: Math.round(source.d5vv1),
            },
            vd: {
              0: Math.round(source.d5bd2),
              1: Math.round(source.d5bv2),
              2: Math.round(source.d5vd2),
              3: Math.round(source.d5vv2),
            },
            vv: {
              0: Math.round(source.d5bd3),
              1: Math.round(source.d5bv3),
              2: Math.round(source.d5vd3),
              3: Math.round(source.d5vv3),
            },
          },
          info: RBP_LIte_index_help['5'][`description_${language}`],
        };
      }
      case 'd11': {
        return {
          title: RBP_LIte_index_help['11'][`name_${language}`],
          metric: 'd11',
          type: 'bar',
          data: {
            4: {
              i: source.d11i4,
            },
            3: {
              i: source.d11i3,
            },
            2: {
              i: source.d11i2,
            },
            1: {
              i: source.d11i1,
            },
          },
          info: RBP_LIte_index_help['11'][`description_${language}`],
        };
      }
      case 'd9':
        return {
          type: 'table',
          metric: 'd9',
          title: RBP_LIte_index_help['9'][`name_${language}`],
          data: d9Source.map((row, index) => {
            return {
              ...row,
              key: `d9_${index}`,
              no: row.floor.no ? Math.round(row.floor.no) : '-',
              1: row.floor['1'] ? Math.round(row.floor['1']) : '-',
              '2+': row.floor['2+'] ? Math.round(row.floor['2+']) : '-',
            };
          }),
          columns: [
            {
              title: 'Тип помещения',
              dataIndex: 'name',
              key: 'd9_name',
            },
            {
              title: 'Без учета этажа',
              dataIndex: 'no',
              key: 'd9_no',
              render: (value) => (value !== '-' ? addSeparators(value) : value),
            },
            // {
            //   title: '-1 этаж',
            //   dataIndex: 'minus_floor',
            //   key: 'd9_minus_floor',
            // },
            {
              title: '1 этаж',
              dataIndex: '1',
              key: 'd9_1',
              render: (value) => (value !== '-' ? addSeparators(value) : value),
            },
            {
              title: '2 этаж',
              dataIndex: '2+',
              key: 'd9_2+',
              render: (value) => (value !== '-' ? addSeparators(value) : value),
            },
          ],
          info: RBP_LIte_index_help['9'][`description_${language}`],
          // subTable: {
          //   data: d9Source.map((row) => {
          //     return {
          //       ...row,
          //       ...row.floor,
          //     };
          //   }),
          //   columns: [
          //     {
          //       title: 'Общее назначение',
          //       dataIndex: 'common_use',
          //       key: 'common_use',
          //     },
          //     {
          //       dataIndex: 'no_floor',
          //       key: 'no_floor',
          //     },
          //     {
          //       dataIndex: 'minus_floor',
          //       key: 'minus_floor',
          //     },
          //     {
          //       dataIndex: 'first_floor',
          //       key: 'first_floor',
          //     },
          //     {
          //       dataIndex: 'second_floor',
          //       key: 'second_floor',
          //     },
          //   ],
          // },
        };
    }
  });
};

export const formatLiteHexagons = (hexagons, aggregates) => {
  const category_keys = Object.keys(hexagons[0]).filter((item) =>
    /^d1[a-z]/g.test(item)
  );

  return hexagons.map((item, index) => {
    const geometry = zoom9.features.find(
      (feature) => feature.id === item.zoom9_id
    )?.geometry;
    return {
      geometry,
      id: index,
      type: 'Feature',
      properties: {
        ...item,
        index_main: aggregates.isochrone.d1.current[item.zoom9_id],
        // category_keys.reduce((a, b) => a + item[b], 0),
        d11i1: +(item.d11i1 * 10).toFixed(1),
        d11i2: +(item.d11i2 * 10).toFixed(1),
        d11i3: +(item.d11i3 * 10).toFixed(1),
        d11i4: +(item.d11i4 * 10).toFixed(1),
      },
    };
  });
};

export const mutateLiteHexagons = (
  hexagons,
  chosen_metric,
  specKeys = [],
  aggregates
) => {
  const regex = new RegExp(`${chosen_metric}[a-z]`, 'g');
  const category_keys = Object.keys(hexagons[0].properties).filter((item) =>
    item.match(regex)
  );

  return hexagons.map((item) => {
    let index_main = (specKeys.length > 0 ? specKeys : category_keys).reduce(
      (a, b) => a + item.properties[b],
      0
    );
    if (chosen_metric === 'd11') {
      index_main /= specKeys.length || 4;
    } else if (aggregates) {
      index_main = aggregates[chosen_metric].current[item.properties.zoom9_id];
    }
    if (chosen_metric === 'd7') {
      index_main =
        item.properties[category_keys.find((key) => /d7t\d+$/g.test(key))];
    }
    return {
      ...item,
      properties: {
        ...item.properties,
        index_main,
      },
    };
  });
};
