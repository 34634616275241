import { sample } from 'effector';
import {
  $cardsLoader,
  $loader,
  $radarLoader,
  $webSocketConnected,
} from './stores.js';
import {
  changeWebSocketConnectedEv,
  delayedHideLoaderEv,
  hideCardsLoaderEv,
  hideLoaderEv,
  hideRadarLoaderEv,
  initPublicWsEv,
  showCardsLoaderEv,
  showLoaderEv,
  showRadarLoaderEv,
} from './events.js';
import { delayFx } from './effects.js';
import { $authStatus, loginFx } from '../authModel/index.js';
import { wsOnMessageHandler } from '../../utils/webSocketConfig.js';
import { cookies } from '../../api/axiosinstance.js';

$webSocketConnected.on(changeWebSocketConnectedEv, (_, payload) => payload);

$loader.on(showLoaderEv, (_, payload) => true);
$loader.on(hideLoaderEv, (_, payload) => false);
$loader.on(delayFx.doneData, (_, payload) => payload);

$radarLoader
  .on(showRadarLoaderEv, (_, payload) => true)
  .reset(hideRadarLoaderEv);
$cardsLoader
  .on(showCardsLoaderEv, (_, payload) => true)
  .reset(hideCardsLoaderEv);

sample({
  source: $loader,
  clock: delayedHideLoaderEv,
  filter: (source) => source,
  target: delayFx,
});

// FIXME Добавить wathc на loginFx.doneData чтобы инициализировать webSocket из main.jsx

sample({
  clock: $authStatus.updates,
  fn: (clock) => {
    if (clock) {
      const token = cookies.get('access_token');

      window.ws = new WebSocket(`${__WSS_URL__}?tk=${token}`);

      window.ws.onopen = () => {
        console.log('WEBSOCKET:: Connected');
        changeWebSocketConnectedEv(true);
      };

      window.ws.onclose = () => {
        console.log('WEBSOCKET:: Disconnected');
        changeWebSocketConnectedEv(false);
      };

      window.ws.onmessage = (message) => {
        wsOnMessageHandler(message);
      };
    }
  },
});

sample({
  clock: initPublicWsEv,
  fn: () => {
    window.wsp = new WebSocket(`${__WSS_URL__}`);

    window.wsp.onopen = () => {
      console.log('WEBSOCKET:: Connected');
      changeWebSocketConnectedEv(true);
    };

    window.wsp.onclose = () => {
      console.log('WEBSOCKET:: Disconnected');
      changeWebSocketConnectedEv(false);
    };

    window.wsp.onmessage = (message) => {
      wsOnMessageHandler(message);
    };
  },
});
