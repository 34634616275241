import React from 'react';
import { Button } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import {
  $fullScreen,
  toggleFullScreenEv,
} from '../../models/mapModel/index.js';

function MapFullScreen(props) {
  const fullScreen = useUnit($fullScreen);
  const toggleFullScreen = useUnit(toggleFullScreenEv);

  const onClick = () => {
    if (fullScreen) {
      document.exitFullscreen();
    } else {
      document.getElementById('map_container').requestFullscreen();
    }
    toggleFullScreen();
  };

  window.addEventListener('fullscreenchange', (e) => {
    if (!document.fullscreenElement) {
      toggleFullScreen(false);
    }
  });

  return (
    <Button
      style={{
        ...props.style,
        position: 'absolute',
        bottom: 20,
        left: 20,
      }}
      icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
      onClick={onClick}
    />
  );
}

export default MapFullScreen;
