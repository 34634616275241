export const lite_krisha_types = {
  23: 'Сельское хозяйство',
  29: 'Конференц-залы',
  30: 'Кабинеты и рабочие места',
  22: 'Салоны красоты',
  19: 'Склады',
  24: 'Бани, гостиницы, зоны отдыха',
  20: 'АЗС, Автосервисы',
  28: 'Развлечения',
  16: 'Свободное назначение',
  31: 'Студии',
  17: 'Офисы',
  18: 'Магазины и бутики',
  27: 'Образование',
  21: 'Общепит',
  26: 'Медцентры и аптеки',
  25: 'Фитнес и спорт',
};
