import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useUnit } from 'effector-react';
import { $interactivity, $mapLoaded } from '../../../models/mapModel/index.js';
import { $activeLayers } from '../../../models/tezberModel/index.js';
import { changeLayerVisibility } from '../../../utils/mapbox-utils.js';

const handleClickCluster = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: [`tezber_${layerName}_layer`],
  });
  const clusterId = features[0].properties.cluster_id;
  window.map
    .getSource(`tezber_${layerName}_source`)
    .getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      window.map.easeTo({
        center: features[0].geometry.coordinates,
        zoom,
      });
    });
};

const handleClickDot = (e) => {
  const feature = e.features[0];
  const coordinates = feature.geometry.coordinates.slice();
  const html = `
            <div class="popup_content_wrapper">
              <div class="popup_title">Существующие ПВЗ</div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Название ПВЗ:</div> 
                <div class="popup_item_value">${
                  feature.properties.name || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Охват ЦА:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_18_60 || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Охват населения:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_total || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Грав. модель ЦА:</div> 
                <div class="popup_item_value">${
                  feature.properties.GRAV_pop_18_60 || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Грав. модель общий:</div> 
                <div class="popup_item_value">${
                  feature.properties.GRAV_pop_total || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Регион:</div> 
                <div class="popup_item_value">${
                  feature.properties.region || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Населенный пункт:</div> 
                <div class="popup_item_value">${
                  feature.properties.settlement || '-'
                }</div>
              </div>
            </div>`;

  new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(window.map);
};

function TezberDotsPreset(props) {
  const { dots, tableChosenDots, layerName, color } = props;
  const mapLoaded = useUnit($mapLoaded);
  const activeLayers = useUnit($activeLayers);
  const interactivity = useUnit($interactivity);

  const createLayer = (update) => {
    const dotsData = {
      type: 'FeatureCollection',
      features:
        tableChosenDots.length > 0
          ? dots.filter((item) => tableChosenDots.includes(item.properties.id))
          : dots,
    };

    if (update) {
      window.map.getSource(`tezber_${layerName}_source`).setData(dotsData);
    } else {
      if (!window.map.getSource(`tezber_${layerName}_source`)) {
        window.map.addSource(`tezber_${layerName}_source`, {
          type: 'geojson',
          data: dotsData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
      }

      if (!window.map.getLayer(`tezber_${layerName}_layer`)) {
        // FIXME Cluster layer
        window.map.addLayer({
          id: `tezber_${layerName}_layer`,
          type: 'circle',
          source: `tezber_${layerName}_source`,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': color,
            'circle-radius': 10,
          },
        });

        // FIXME Cluster count layer
        window.map.addLayer({
          id: `tezber_${layerName}_count_layer`,
          type: 'symbol',
          source: `tezber_${layerName}_source`,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-size': 12,
          },
        });

        // FIXME Unclustered dots layer
        window.map.addLayer({
          id: `tezber_${layerName}_unclustered_layer`,
          type: 'circle',
          source: `tezber_${layerName}_source`,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': color,
            'circle-radius': 10,
          },
        });

        // FIXME Zoom in on cluster click + cursor on hover
        window.map.on('click', `tezber_${layerName}_layer`, handleClickCluster);

        // window.map.on('mouseenter', `tezber_${layerName}_layer`, () => {
        //   window.map.getCanvas().style.cursor = 'pointer';
        // });
        //
        // window.map.on('mouseleave', `tezber_${layerName}_layer`, () => {
        //   window.map.getCanvas().style.cursor = '';
        // });

        // FIXME Popup on dot click + cursor on hover
        window.map.on(
          'click',
          `tezber_${layerName}_unclustered_layer`,
          handleClickDot
        );

        // window.map.on(
        //   'mouseenter',
        //   `tezber_${layerName}_unclustered_layer`,
        //   () => {
        //     window.map.getCanvas().style.cursor = 'pointer';
        //   }
        // );
        //
        // window.map.on(
        //   'mouseleave',
        //   `tezber_${layerName}_unclustered_layer`,
        //   () => {
        //     window.map.getCanvas().style.cursor = '';
        //   }
        // );

        setTimeout(() => {
          window.map.moveLayer(`tezber_${layerName}_unclustered_layer`);
          window.map.moveLayer(`tezber_${layerName}_layer`);
          window.map.moveLayer(`tezber_${layerName}_count_layer`);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && dots) {
      if (window.map.getLayer(`tezber_${layerName}_layer`)) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [mapLoaded, dots, tableChosenDots]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer(`tezber_${layerName}_layer`)) {
      if (!activeLayers.includes(layerName)) {
        changeLayerVisibility(`tezber_${layerName}_layer`, false);
        changeLayerVisibility(`tezber_${layerName}_count_layer`, false);
        changeLayerVisibility(`tezber_${layerName}_unclustered_layer`, false);
      } else {
        changeLayerVisibility(`tezber_${layerName}_layer`, true);
        changeLayerVisibility(`tezber_${layerName}_count_layer`, true);
        changeLayerVisibility(`tezber_${layerName}_unclustered_layer`, true);
      }
    }
  }, [mapLoaded, activeLayers]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer(`tezber_${layerName}_layer`)) {
      if (interactivity) {
        window.map.on('click', `tezber_${layerName}_layer`, handleClickCluster);
        window.map.on(
          'click',
          `tezber_${layerName}_unclustered_layer`,
          handleClickDot
        );
      } else {
        window.map.off(
          'click',
          `tezber_${layerName}_layer`,
          handleClickCluster
        );
        window.map.off(
          'click',
          `tezber_${layerName}_unclustered_layer`,
          handleClickDot
        );
      }
    }
  }, [mapLoaded, interactivity]);

  return null;
}

export default TezberDotsPreset;
