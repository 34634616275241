import React, { useState, useEffect } from 'react';
import ab_ai_icon from '../../../assets/ab_ai_icon.svg';
// FIXME RCP URL FOR AI ASSISTANT
// const apiUrl = 'https://asst.metatable.ai/create_message'
// const apiUrl = 'https://atamekenvc.metatable.ai/create_message';
const authToken =
  '2b993bb5aa86d16f300224326e7f864804ddb99b2dd91f017367bc5c9aae5621';

function ChatV2(props) {
  const { apiUrl, promptMessage } = props;

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    // Добавляем стартовое сообщение при загрузке виджета
    const initialMessage = {
      text: promptMessage,
      fromUser: false,
    };
    setMessages([initialMessage]);
  }, []);
  function processText(input) {
    // Разбиваем текст на строки
    const lines = input.split(/(?<=\\.)\\d+\\.\\s/); // Разделяем по номеру с точкой и пробелом
    const result = [];

    lines.forEach((line) => {
      // Убираем `**` и заменяем содержимое внутри `【】` на пустую строку
      const cleanedLine = line
        .replace(/\\*\\*/g, '')
        .replace(/【[^】]*】/g, '')
        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
        .replace(/(\s\d+\.)/g, '<br/>$1')
        .replace(/(\s-\s)/g, '<br/>$1');

      if (cleanedLine.trim()) {
        result.push(cleanedLine.trim());
      }
    });

    // Возвращаем текст с новой строки для каждого пункта
    return result.join('\\n');
  }

  const sendMessage = async () => {
    if (!input.trim()) return;

    // Отображаем исходящее сообщение
    const newMessages = [...messages, { text: input, fromUser: true }];
    setMessages(newMessages);
    setInput('');
    setIsTyping(true);

    // Отправляем сообщение в API
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: input,
          thread_id: threadId,
        }),
      });
      const data = await response.json();

      // Сохраняем thread_id при первом ответе
      if (!threadId) {
        setThreadId(data.thread_id);
      }

      // Анимация печати входящего сообщения
      animateIncomingMessage(data.message, newMessages);
    } catch (error) {
      console.error('Ошибка при запросе API:', error);
    } finally {
      setIsTyping(false);
    }
  };

  // Функция для анимации печати текста побуквенно
  const animateIncomingMessage = (text, newMessages) => {
    let index = 0;
    const intervalId = setInterval(() => {
      setMessages([
        ...newMessages,
        { text: processText(text.slice(0, index + 1)), fromUser: false },
      ]);
      index++;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 5); // Скорость печати в миллисекундах
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.messagesContainer}>
        {messages.map((msg, index) => {
          const msgEl = (
            <div
              key={index}
              style={{
                ...styles.message,
                ...(msg.fromUser ? styles.userMessage : styles.apiMessage),
              }}
            >
              <p
                style={styles.messageText}
                dangerouslySetInnerHTML={{ __html: msg.text }} // Используем dangerouslySetInnerHTML для отображения HTML
              />
            </div>
          );

          if (!msg.fromUser) {
            return (
              <div style={styles.apiMessage_wrapper}>
                <div style={styles.api_avatar}>
                  <img src={ab_ai_icon} />
                </div>
                {msgEl}
              </div>
            );
          }
          return msgEl;
        })}
        {isTyping && (
          <div style={styles.apiMessage_wrapper}>
            <div style={styles.api_avatar}>
              <img src={ab_ai_icon} />
            </div>
            <div style={styles.typingIndicator}>
              <p>Typing...</p>
            </div>
          </div>
        )}
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Задайте вопрос Виртуальному консультанту"
          style={styles.input}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
        />
        <button onClick={sendMessage} style={styles.sendButton}>
          Отправить
        </button>
      </div>
    </div>
  );
}

const styles = {
  chatContainer: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
    height: '100%',
  },
  messagesContainer: {
    background: 'linear-gradient(118.17deg, #EDF7EE 0%, #CEFFD3 100%)',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    height: 'calc(100% - 78px)',
  },
  message: {
    marginBottom: '10px',
    padding: '8px 12px',
    maxWidth: '80%',
  },
  userMessage: {
    background: '#F8FFF8',
    alignSelf: 'flex-end',
    textAlign: 'right',
    marginLeft: 'auto',
    color: '#1B1C1D',
    borderRadius: '24px 0px 24px 24px',
  },
  apiMessage: {
    backgroundColor: '#FFF',
    color: '#1B1C1D',
    textAlign: 'left',
    borderRadius: '0px 24px 24px 24px',
  },
  typingIndicator: {
    backgroundColor: '#FFF',
    color: '#1B1C1D',
    textAlign: 'left',
    borderRadius: '0px 24px 24px 24px',
    marginBottom: '10px',
    padding: '8px 12px',
    maxWidth: '80%',
    fontStyle: 'italic',
    fontSize: '16px',
    lineHeight: '28px',
  },
  messageText: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: 'Inter',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '19px 20px 20px 20px',
    borderTop: '1px solid #ddd',
    backgroundColor: '#fff',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '6px',
    border: '1px solid #ddd',
    fontSize: '14px',
    outline: 'none',
    marginRight: '10px',
  },
  sendButton: {
    padding: '11px 20px',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#30A93E',
    color: '#fff',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  sendButtonHover: {
    backgroundColor: '#0056b3',
  },
  apiMessage_wrapper: {
    display: 'flex',
    columnGap: 15,
  },
  api_avatar: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default ChatV2;
