import React from 'react';
import { Button, Card, Segmented } from 'antd';
import { useUnit } from 'effector-react';
import style from './Routing.module.css';
import {
  $isRouting,
  $routingData,
  $routingPointType,
  $routingType,
  changeRoutingTypeEv,
  setRoutingPointTypeEv,
} from '../../../models/tezberModel/index.js';
import { MarkerIcon } from '../../IsochroneModal/IsochroneModal.jsx';

function RoutingPopup() {
  const changeRoutingType = useUnit(changeRoutingTypeEv);
  const routingType = useUnit($routingType);
  const setRoutingPointType = useUnit(setRoutingPointTypeEv);
  const routingPointType = useUnit($routingPointType);
  const routingData = useUnit($routingData);
  const isRouting = useUnit($isRouting);

  const segmentedOptions = [
    {
      label: 'Пешеход',
      value: 'pedestrian',
    },
    {
      label: 'Авто',
      value: 'car',
    },
  ];

  return (
    <Card
      className={style.routing_popup}
      style={{
        left: isRouting ? 62 : '-100%',
      }}
    >
      <Segmented
        options={segmentedOptions}
        onChange={changeRoutingType}
        value={routingType}
        block
      />
      <div className={style.routing_content_wrapper}>
        <div className={style.routing_point_button_wrapper}>
          <div className={style.routing_point_button}>
            <Button
              type={routingPointType === 'start' ? 'primary' : 'default'}
              icon={<MarkerIcon />}
              onClick={() => setRoutingPointType('start')}
            />{' '}
            Начальная точка
          </div>
          <div className={style.routing_point_button}>
            <Button
              type={routingPointType === 'finish' ? 'primary' : 'default'}
              icon={<MarkerIcon />}
              onClick={() => setRoutingPointType('finish')}
            />{' '}
            Конечная точка
          </div>
        </div>
        <div className={style.routing_info_wrapper}>
          <div>
            <div>Протяженность маршрута:</div>
            <div style={{ marginTop: 5 }}>{routingData.distance} км</div>
          </div>
          <div>
            <div>Время в пути:</div>
            <div style={{ marginTop: 5 }}>
              {routingData.timeH} ч {routingData.timeM} мин
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RoutingPopup;
