import { createStore } from 'effector';

export const $language = createStore('ru');

export const $isError = createStore(false);

export const $isSuccess = createStore(false);

export const $menuModal = createStore(false);

export const $chosenPoint = createStore(null);
$chosenPoint.watch((state) => console.log('$chosenPoint', state));

export const $secondGradient = createStore(false)
