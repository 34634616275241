import {
  changeDrawModeEv,
  changeInteractivityEv,
} from '../models/mapModel/index.js';
import { changeActiveFilterEv } from '../models/activeFiltersModel/index.js';
import { showLoaderEv } from '../models/webSocketModel/index.js';
import { wsGetIsochrone } from './webSocketConfig.js';
import {
  clearIsochroneEv,
  getIsochroneByPointEv,
  getIsochroneForPaymentsEv,
} from '../models/isochroneModel/index.js';
import { saveHexDataEv } from '../models/indexCardsModel/index.js';
import { setChosenPointEv } from '../models/rbpLandingModel/index.js';
import { getNewLocationEv } from '../models/rbpLiteModel/index.js';

export const modeChangeHandler = (e) => {
  changeDrawModeEv(e.mode);
  if (e.mode === 'draw_polygon' || e.mode === 'draw_point') {
    changeInteractivityEv(false);
  }
  if (e.mode === 'simple_select') {
    setTimeout(() => {
      changeInteractivityEv(true);
    }, 100);
  }
};

export const createObject = (e) => {
  const mode = window.draw.getMode();
  const drawn_objects = window.draw.getAll().features;
  if (mode === 'draw_polygon') {
    console.log('created polygon', e.features);
    // window.draw.set({
    //   type: 'FeatureCollection',
    //   features: [
    //     ...drawn_objects.filter((item) => item.geometry.type !== 'Polygon'),
    //     e.features[0],
    //   ],
    // });
    showLoaderEv();
    changeActiveFilterEv({
      field: 'draw_polygon',
      value: drawn_objects
        .filter((item) => item.geometry.type == 'Polygon')
        .map((item) => {
          return {
            ...item,
            properties: {
              name: item.properties.name || `Area ${drawn_objects.length + 1}`,
            },
          };
        }),
    });
  } else {
    console.log('created point', e.features);
    const formatedPoint = {
      ...e.features[0],
      properties: {
        name: `isochrone_lat_${e.features[0].geometry.coordinates[1]}_lon_${e.features[0].geometry.coordinates[0]}`,
      },
    };
    if (
      window.location.pathname !== '/payment' &&
      window.location.pathname !== '/lite'
    ) {
      window.draw.set({
        type: 'FeatureCollection',
        features: [
          ...drawn_objects.filter((item) => item.geometry.type !== 'Point'),
          // ...drawn_objects,
          formatedPoint,
        ],
      });
      getIsochroneByPointEv(e.features[0]);
    }
    if (window.location.pathname === '/payment') {
      window.draw.set({
        type: 'FeatureCollection',
        features: [],
      });
      getIsochroneForPaymentsEv({
        lat: e.features[0].geometry.coordinates[1],
        lon: e.features[0].geometry.coordinates[0],
      });
      setChosenPointEv({
        lat: e.features[0].geometry.coordinates[1],
        lng: e.features[0].geometry.coordinates[0],
      });
      const url = new URL(window.location.href);
      url.searchParams.set('lat', e.features[0].geometry.coordinates[1]);
      url.searchParams.set('lng', e.features[0].geometry.coordinates[0]);
      window.history.pushState({}, '', url);
    }
    if (window.location.pathname === '/lite') {
      window.draw.set({
        type: 'FeatureCollection',
        features: [],
      });
      getNewLocationEv(e.features[0]);
    }
  }
};

export const deleteAll = (e) => {
  window.draw.set({
    type: 'FeatureCollection',
    features: [],
  });
  changeActiveFilterEv({
    field: 'draw_polygon',
    value: [],
  });
  clearIsochroneEv();
  changeActiveFilterEv({
    field: `zoom9_hex`,
    value: {
      id: '',
      centerCoords: [],
    },
  });
  saveHexDataEv({
    level: `zoom_9`,
    data: {
      id: '',
      indexes: [],
    },
  });
};

export const updatePolygon = (e) => {
  const { features } = window.draw.getAll();
  changeActiveFilterEv({
    field: 'draw_polygon',
    value: [
      ...features.filter((item) => item.id !== e.features[0].id),
      e.features[0],
    ],
  });
};

// FIXME Custom style for polygons
export const customStylesDark = [
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': 'white',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': 'white',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': 'white',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': 'white',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': 'white',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': 'white',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': 'white',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
];

export const customStylesLight = [
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#242121',
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#242121',
    },
  },
];

// FIXME Class for custom buttons (found in internet)
export class ExtendDrawBar {
  constructor(opt) {
    const ctrl = this;
    ctrl.draw = opt.draw;
    ctrl.buttons = opt.buttons || [];
    ctrl.onAddOrig = opt.draw.onAdd;
    ctrl.onRemoveOrig = opt.draw.onRemove;
  }

  onAdd(map) {
    const ctrl = this;
    ctrl.map = map;
    ctrl.elContainer = ctrl.onAddOrig(map);
    ctrl.buttons.forEach((b) => {
      ctrl.addButton(b);
    });
    return ctrl.elContainer;
  }

  onRemove(map) {
    const ctrl = this;
    ctrl.buttons.forEach((b) => {
      ctrl.removeButton(b);
    });
    ctrl.onRemoveOrig(map);
  }

  addButton(opt) {
    const ctrl = this;
    const elButton = document.createElement('button');
    elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    elButton.addEventListener(opt.on, opt.action);
    ctrl.elContainer.appendChild(elButton);
    opt.elButton = elButton;
  }

  removeButton(opt) {
    opt.elButton.removeEventListener(opt.on, opt.action);
    opt.elButton.remove();
  }
}

export const changeDrawStyles = (darkMode) => {
  const source = darkMode ? customStylesDark : customStylesLight;

  source.forEach((layer) => {
    Object.entries(layer.paint).forEach(([property, value]) => {
      window.map.setPaintProperty(`${layer.id}.cold`, property, value);
      window.map.setPaintProperty(`${layer.id}.hot`, property, value);
    });
  });
};
