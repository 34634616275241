export const charts_names_dict = {
  m: 'Мужчины',
  w: 'Женщины',
  mkd: 'В многоквартирных домах',
  izs: 'В частных домах',
  lv: 'Проживающее население',
  wk: 'Работающее население',
  bd: 'Будни, день',
  bv: 'Будни, вечер',
  vd: 'Выходные, день',
  vv: 'Выходные, вечер',
};

export const d5names = {
  0: 'Проживающее и работающее',
  1: 'Проживающее',
  2: 'Работающее',
  3: 'Транзитное',
};

export const d11names = {
  1: 'Пробки',
  2: 'Остановки',
  3: 'Маршруты',
  4: 'Парковки',
};
