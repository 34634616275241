import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import {
  $interactivity,
  $layersOpacity,
  $mapLoaded,
} from '../../../models/mapModel/index.js';
import {
  $showPopulationHex,
  $tezberPopulationHex,
} from '../../../models/tezberModel/index.js';
import { $gradientBuckets } from '../../../models/gradientModel/index.js';
import { formDynamicBuckets } from '../../../utils/hexagon-utils.js';
import { changeLayerVisibility } from '../../../utils/mapbox-utils.js';

const handleClick = (e) => {
  const clickedFeatures = window.map.queryRenderedFeatures(e.point, {
    layers: [
      'tezber_population_hex_layer',
      'tezber_dots_unclustered_layer',
      'tezber_cdek_unclustered_layer',
      'tezber_express_unclustered_layer',
      'tezber_janaPost_unclustered_layer',
      'tezber_kimigo_unclustered_layer',
      'tezber_ozon_unclustered_layer',
      'tezber_wildberries_unclustered_layer',
    ],
  });
  if (clickedFeatures[0].layer.id === 'tezber_population_hex_layer') {
    const feature = e.features[0];
    const html = `
            <div class="popup_content_wrapper">
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Экономиски активное:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_18_60 || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Общее:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_total || '-'
                }</div>
              </div>
            </div>`;

    new mapboxgl.Popup()
      .setLngLat([e.lngLat.lng, e.lngLat.lat])
      .setHTML(html)
      .addTo(window.map);
  }
};

function TezberPopulationHex() {
  const mapLoaded = useUnit($mapLoaded);
  const tezberPopulationHex = useUnit($tezberPopulationHex);
  const { buckets, colors } = useUnit($gradientBuckets);
  const showPopulationHex = useUnit($showPopulationHex);
  const layersOpacity = useUnit($layersOpacity);
  const interactivity = useUnit($interactivity);

  const createLayer = (update) => {
    const tezberPopulationHexData = {
      type: 'FeatureCollection',
      features: tezberPopulationHex,
    };

    const gradientProp = formDynamicBuckets(buckets, colors);

    if (update) {
      window.map
        .getSource('tezber_population_hex_source')
        .setData(tezberPopulationHexData);
      window.map.setPaintProperty(
        'tezber_population_hex_layer',
        'fill-color',
        gradientProp
      );
      window.map.setPaintProperty(
        'tezber_population_hex_outline',
        'line-color',
        gradientProp
      );
    } else {
      if (!window.map.getSource('tezber_population_hex_source')) {
        window.map.addSource('tezber_population_hex_source', {
          type: 'geojson',
          data: tezberPopulationHexData,
        });
      }

      if (!window.map.getLayer('tezber_population_hex_layer')) {
        window.map.addLayer({
          id: 'tezber_population_hex_layer',
          type: 'fill',
          source: 'tezber_population_hex_source',
          paint: {
            'fill-color': gradientProp,
            'fill-opacity': layersOpacity,
          },
        });
        // window.map.on('click', 'tezber_population_hex_layer', handleClick);

        // window.map.on('mouseenter', 'tezber_population_hex_layer', () => {
        //   window.map.getCanvas().style.cursor = 'pointer';
        // });
        //
        // window.map.on('mouseleave', 'tezber_population_hex_layer', () => {
        //   window.map.getCanvas().style.cursor = '';
        // });
      }
      if (!window.map.getLayer('tezber_population_hex_outline')) {
        window.map.addLayer({
          id: 'tezber_population_hex_outline',
          type: 'line',
          source: 'tezber_population_hex_source',
          paint: {
            'line-color': gradientProp,
            'line-width': 1,
            'line-opacity': layersOpacity,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && buckets.length > 0 && tezberPopulationHex.length > 0) {
      if (!window.map.getLayer('tezber_population_hex_layer')) {
        createLayer(false);
      } else {
        setTimeout(() => {
          createLayer(true);
        }, 500);
      }
    }
  }, [mapLoaded, tezberPopulationHex, buckets, colors]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('tezber_population_hex_layer')) {
      if (showPopulationHex) {
        changeLayerVisibility('tezber_population_hex_layer', true);
        changeLayerVisibility('tezber_population_hex_outline', true);
      } else {
        changeLayerVisibility('tezber_population_hex_layer', false);
        changeLayerVisibility('tezber_population_hex_outline', false);
      }
    }
  }, [showPopulationHex, mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('tezber_population_hex_layer')) {
      setTimeout(() => {
        window.map.setPaintProperty(
          'tezber_population_hex_layer',
          'fill-opacity',
          layersOpacity
        );
        window.map.setPaintProperty(
          'tezber_population_hex_outline',
          'line-opacity',
          layersOpacity
        );
      }, 100);
    }
  }, [layersOpacity, mapLoaded]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('tezber_population_hex_layer')) {
      if (interactivity) {
        window.map.on('click', 'tezber_population_hex_layer', handleClick);
      } else {
        window.map.off('click', 'tezber_population_hex_layer', handleClick);
      }
    }
  }, [interactivity, mapLoaded]);

  return null;
}

export default TezberPopulationHex;
