import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  $chartFilters,
  changeChartFiltersEv,
} from '../../../models/rbpLiteModel/index.js';
import {
  age_dictionary,
  income_dictionary,
} from '../../../dictionaries/socdem_dictionaries.js';
import { charts_names_dict, d11names, d5names } from './charts_names_dict.js';
import { addSeparators } from '../../../utils/format-utils.js';

function LiteBarChart({ data, metrics, bt }) {
  const darkMode = useUnit($isDarkTheme);
  const chartFilters = useUnit($chartFilters);
  const changeChartFilters = useUnit(changeChartFiltersEv);
  const chartRef = useRef();

  const handleChartClick = (event, chartContext, config) => {
    const { name } = config.config.series[config.seriesIndex];
    const category = config.config.xaxis.categories[config.dataPointIndex];

    const payload = {
      category: name,
      column: category,
      metrics,
      bt,
      // chart: title,
    };

    changeChartFilters(payload);
  };

  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        // dataLabels: {
        //   position: 'top',
        // },
        horizontal: true,
      },
    },
    chart: {
      stacked: true,
      // stackType: metrics === 'd8' || metrics === 'd6' ? '100%' : 'normal',
      toolbar: {
        show: false,
      },
      events: {
        click: handleChartClick,
        dataPointMouseEnter(event) {
          event.target.style.cursor = 'pointer';
        },
      },
    },
    dataLabels: {
      enabled: metrics === 'd8' || metrics === 'd6',
      formatter: (value, opts) => {
        if (metrics === 'd8' || metrics === 'd6') {
          const total = opts.config.series.reduce((a, b) => a + b.data[0], 0);
          return `${Math.round((value * 100) / total)}%`;
        }
      },
    },
    xaxis: {
      // categories: Object.keys(data).map((key) => charts_names_dict[key]),
      categories: Object.keys(data),
      labels: {
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
        formatter: (value) => {
          if (metrics === 'd10') return addSeparators(value / 1000);
          return addSeparators(value);
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          if (metrics === 'd11') return d11names[value];
          if (charts_names_dict[value]) return charts_names_dict[value];
          return value;
        },
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
      },
    },
    legend: {
      formatter: (value) => {
        if (metrics === 'd5') return d5names[value];
        if (metrics === 'd8' || metrics === 'd6')
          return charts_names_dict[value];
        return value;
      },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    tooltip: {
      showForNullSeries: false,
      theme: darkMode ? 'dark' : 'light',
      x: {
        formatter: (value) => {
          if (metrics === 'd11') return d11names[value];
          if (charts_names_dict[value]) return charts_names_dict[value];
          return value;
        },
      },
      y: {
        formatter: (value) => addSeparators(value),
        title: {
          formatter: (value, e) => {
            if (metrics === 'd5') return d5names[value];
            if (metrics === 'd8' || metrics === 'd6')
              return charts_names_dict[value];
            if (metrics === 'd10') return 'Сумма';
            return 'Индекс';
            // return `Индекс ${
            //   e.w.config.series[e.seriesIndex].data[e.dataPointIndex]
            // }`;
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'lighten',
          value: 0.3,
        },
      },
    },
  };

  let series = {};
  Object.keys(data).forEach((item) => {
    Object.keys(data[item]).forEach((field) => {
      if (!series[field]) {
        series[field] = [data[item][field]];
      } else {
        series[field] = [...series[field], data[item][field]];
      }
    });
  });

  series = Object.keys(series).map((key) => {
    return {
      // name: metrics === 'd5' ? d5names[key] : key,
      name: key,
      data: series[key],
    };
  });

  useEffect(() => {
    const { selectedDataPoints } = chartRef.current.chart.w.globals;
    if (
      (chartFilters.length > 0 && chartFilters[0].metrics !== metrics) ||
      (chartFilters.length === 0 && selectedDataPoints.length > 0)
    ) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));
      if (copy.length > 0) {
        copy.forEach((item, seriesIndex) => {
          if (item && item.length > 0) {
            item.forEach((dataIndex) => {
              chartRef.current.chart.ctx.toggleDataPointSelection(
                seriesIndex,
                dataIndex
              );
            });
          }
        });
      }
    }
    if (chartFilters.length > 0 && chartFilters[0].metrics === metrics) {
      const copy = JSON.parse(JSON.stringify(selectedDataPoints));

      const dict = Object.hasOwn(chartFilters[0], 'age')
        ? age_dictionary
        : income_dictionary;
      const field = chartFilters[0].age ? 'age' : 'income';
      const chartLabels = chartRef.current.chart.w.globals.labels;
      if (copy.length === 0) {
        chartFilters.forEach((filter) => {
          const seriesIndex = +filter.gender - 1;
          const element = chartLabels.find((item) =>
            item.includes(dict[filter[field]])
          );
          const dataPointIndex = chartLabels.indexOf(element);

          chartRef.current.chart.ctx.toggleDataPointSelection(
            seriesIndex,
            dataPointIndex
          );
        });
      }
    }
  }, [chartFilters]);

  return (
    <Chart
      ref={chartRef}
      options={options}
      series={series}
      type="bar"
      width={390}
      padding="15"
    />
  );
}

export default LiteBarChart;
