import React from 'react';
import { useUnit } from 'effector-react';
import { $language } from '../../../models/rbpLandingModel/index.js';
import useWindowSize from '../../../hooks/useWindowSize.js';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import footer_logo from '../../../assets/RbpLandingImages/Footer/footer_logo.svg';
import rbp_report_example from '../../../data/rbp_report_example.pdf';
import public_offer from '../../../data/public_offer.pdf';
import user_agreement from '../../../data/user_agreement.pdf';

function Footer() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const downloadPdf = (type) => {
    const link = document.createElement('a');
    link.href = type === 'public' ? public_offer : user_agreement;
    link.download =
      type === 'public' ? 'Public offer.pdf' : 'User agreement.pdf';
    link.click();
    link.remove();
  };

  return (
    <div className={style.footer_wrapper}>
      <div className={style.container}>
        <div className={style.footer_content}>
          <img src={footer_logo} style={{ height: width <= 1200 ? 30 : 49 }} />
          <div
            className={style.footer_info_block}
            style={{ marginLeft: width <= 1200 ? 'unset' : 136 }}
          >
            <div className={style.footer_info_title}>
              {rbp_landing_translation[language].footer_title_1}
            </div>
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: rbp_landing_translation[language].footer_description_1,
              }}
            />
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: rbp_landing_translation[language].footer_description_2,
              }}
            />
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: rbp_landing_translation[language].footer_description_3,
              }}
            />
          </div>
          <div
            className={style.footer_info_block}
            style={{ marginLeft: width <= 1200 ? 'unset' : 136 }}
          >
            <div className={style.footer_info_title}>
              {rbp_landing_translation[language].footer_title_2}
            </div>
            <div
              className={style.footer_info_description}
              dangerouslySetInnerHTML={{
                __html: rbp_landing_translation[language].footer_description_4,
              }}
            />
            <div className={style.footer_info_description}>
              {rbp_landing_translation[language].footer_description_5}
            </div>
            <div className={style.footer_info_description}>
              {rbp_landing_translation[language].footer_description_6}
            </div>
          </div>
          <div
            className={style.footer_info_block}
            style={{ marginLeft: width <= 1200 ? 'unset' : 136 }}
          >
            <div className={style.footer_info_title}>
              {rbp_landing_translation[language].footer_title_3}
            </div>
            <div
              className={style.footer_info_description}
              onClick={() => downloadPdf('public')}
              style={{ cursor: 'pointer' }}
            >
              {rbp_landing_translation[language].footer_description_7}
            </div>
            <div
              className={style.footer_info_description}
              onClick={() => downloadPdf('agreement')}
              style={{ cursor: 'pointer' }}
            >
              {rbp_landing_translation[language].footer_description_8}
            </div>
          </div>
          {/* <div className={style.footer_info_block}> */}
          {/*   <div className={style.footer_info_title}> */}
          {/*     {rbp_landing_translation[language].footer_title_3} */}
          {/*   </div> */}
          {/*   <div className={style.footer_social_wrapper}> */}
          {/*     <a href=""> */}
          {/*       <img src={footer_instagram} /> */}
          {/*     </a> */}
          {/*     <a href=""> */}
          {/*       <img src={footer_facebook} /> */}
          {/*     </a> */}
          {/*     <a href=""> */}
          {/*       <img src={footer_telegram} /> */}
          {/*     </a> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
        <div className={style.footer_subtitle}>
          {rbp_landing_translation[language].footer_subtitle}
        </div>
      </div>
    </div>
  );
}

export default Footer;
