import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Button, Card, Collapse, Tooltip } from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  InfoCircleFilled,
  RightOutlined,
} from '@ant-design/icons';
import style from './MapDemoHexModal.module.css';
import {
  $chosenZoom7Hexagon,
  $publicBusinessType,
  $zoom7Hexagons,
  resetChosenZoom7HexagonEv,
} from '../../models/zoom7Model/index.js';
import {
  modal_values_dictionary,
  range_values_dict,
} from '../../dictionaries/public_index_dictionary.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import rbp_free_dict from '../../dictionaries/rbp_free_dict.json';
import { $isDarkTheme } from '../../models/authModel/index.js';

const colors = ['#2D9CDB', '#2DDB73'];

const more_colors = [
  '#F85F5F',
  '#2D9CDB',
  '#E4F039',
  '#F09439',
  '#39D4F0',
  '#2DDB73',
];

function RangeProp({ title, total, items }) {
  const isDark = useUnit($isDarkTheme);

  return (
    <div className={style.range_prop}>
      <div
        className={style.prop_title}
        style={{ color: isDark ? 'white' : '#717171' }}
      >
        {title}
      </div>
      <div className={style.line}>
        {items.map((item, index) => {
          const percent = (item.value * 100) / total;
          return (
            <Tooltip title={`${Math.round(percent)}%`}>
              <div
                className={style.line_item}
                style={{
                  width: `${percent}%`,
                  background:
                    items.length === 2 ? colors[index] : more_colors[index],
                }}
              />
            </Tooltip>
          );
        })}
      </div>
      <div className={style.legend_wrapper}>
        {items.map((item, index) => {
          return (
            <div
              className={style.legend_item}
              style={{
                color: isDark ? '#fff' : '#000',
              }}
            >
              <div
                className={style.legend_badge}
                style={{
                  background:
                    items.length === 2 ? colors[index] : more_colors[index],
                }}
              />
              <div>
                {item.label}
                {/* <div style={{ display: 'inline', fontWeight: 'bold' }}> */}
                {/*  {item.value} */}
                {/* </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function MapDemoHexModal() {
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const publicBusinessType = useUnit($publicBusinessType);
  const resetChosenZoom7Hexagon = useUnit(resetChosenZoom7HexagonEv);
  const [col1Key, setCol1Key] = useState([]);
  const [col2Key, setCol2Key] = useState([1]);
  const [col3Key, setCol3Key] = useState([]);
  const zoom7Hexagons = useUnit($zoom7Hexagons);
  const [total, setTotal] = useState({});
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];
  const isDark = useUnit($isDarkTheme);

  useEffect(() => {
    if (
      chosen_metric === 'population_total' ||
      chosen_metric === 'income_avg_range' ||
      chosen_metric === 'households'
    ) {
      setCol1Key([]);
      setCol2Key(['1']);
      setCol3Key([]);
    } else if (chosen_metric === 'competitors_cnt_B') {
      setCol1Key(['1']);
      setCol2Key([]);
      setCol3Key([]);
    } else if (
      chosen_metric === 'tj_lvl_avg' ||
      chosen_metric === 'parking_place_cnt'
    ) {
      setCol1Key([]);
      setCol2Key([]);
      setCol3Key(['1']);
    }
  }, [chosen_metric]);

  useEffect(() => {
    if (zoom7Hexagons.length > 0) {
      const temp = {};
      zoom7Hexagons.forEach((item) => {
        Object.keys(item.properties).forEach((key) => {
          if (temp[key]) {
            temp[key] += item.properties[key];
          } else {
            temp[key] = item.properties[key];
          }
        });
      });
      setTotal({
        ...temp,
        price_per_sq_m_Com: 4740,
        pt_route_cnt: 150,
        population_total: rbp_free_dict.population_total.default_value,
        households: rbp_free_dict.households.default_value,
      });
    }
  }, [zoom7Hexagons]);

  const source = !chosenZoom7Hexagon ? total : chosenZoom7Hexagon.properties;

  return (
    <div
      className={[
        style.hex_info_modal,
        // chosenZoom7Hexagon ? style.show : '',
        style.show,
      ].join(' ')}
      style={{
        background: isDark ? '#1f1f1f' : '#fff',
      }}
    >
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col2Key}
        onChange={(key) => setCol2Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Аудитория
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Население
                  </div>
                  <div className={style.prop_value}>
                    {source.population_total}
                  </div>
                  <div className={style.divider} />
                  <RangeProp
                    title="Пол"
                    total={source.population_total}
                    items={[
                      {
                        label: modal_values_dictionary.population_sex_m,
                        value: source.population_sex_m,
                      },
                      {
                        label: modal_values_dictionary.population_sex_w,
                        value: source.population_sex_w,
                      },
                    ]}
                  />
                  <div className={style.divider} />
                  <RangeProp
                    title="Возраст"
                    total={source.population_total}
                    items={[
                      {
                        label: modal_values_dictionary.population_age_0_15,
                        value: source.population_age_0_15,
                      },
                      {
                        label: modal_values_dictionary.population_age_16_25,
                        value: source.population_age_16_25,
                      },
                      {
                        label: modal_values_dictionary.population_age_26_35,
                        value: source.population_age_26_35,
                      },
                      {
                        label: modal_values_dictionary.population_age_36_45,
                        value: source.population_age_36_45,
                      },
                      {
                        label: modal_values_dictionary.population_age_46_60,
                        value: source.population_age_46_60,
                      },
                      {
                        label: modal_values_dictionary.population_age_61_100,
                        value: source.population_age_61_100,
                      },
                    ]}
                  />
                  <div className={style.divider} />
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Доход
                  </div>
                  {!chosenZoom7Hexagon ? (
                    <div
                      className={style.no_value}
                      style={{ color: isDark ? 'white' : '#717171' }}
                    >
                      <InfoCircleFilled
                        style={{ color: 'red', fontSize: 20 }}
                      />{' '}
                      Выберите область на карте
                    </div>
                  ) : (
                    <div className={style.prop_value}>
                      {range_values_dict[source.income_avg_range]}
                    </div>
                  )}
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Кол-во домохозяйств
                  </div>
                  <div className={style.prop_value}>{source.households}</div>
                </div>
              </div>
            ),
          },
        ]}
      />
      <div className={style.collapse_divider} style={{}} />
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col1Key}
        onChange={(key) => setCol1Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Бизнес
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Конкуренты и смежный бизнес
                  </div>
                  <div className={style.prop_value}>
                    {source[publicBusinessType]}
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Средняя стоимость аренды ком. недвижимости, кв. метр
                  </div>
                  <div className={style.prop_value}>
                    {source.price_per_sq_m_Com}
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Количество БЦ (бизнес-центров)
                  </div>
                  <div className={style.prop_value}>
                    {source.attract_cnt_BC}
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Количество ТЦ (торговых центров)
                  </div>
                  <div className={style.prop_value}>
                    {source.attract_cnt_TC}
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Количество ТРЦ (торгово-развлекательных центров)
                  </div>
                  <div className={style.prop_value}>
                    {source.attract_cnt_TRC}
                  </div>
                </div>
                <div />
              </div>
            ),
          },
        ]}
      />
      <div className={style.collapse_divider} />
      <Collapse
        ghost
        className={style.collapse}
        expandIconPosition="end"
        expandIcon={(panelProps) => (
          <RightOutlined
            style={{ color: isDark ? 'white' : 'black' }}
            rotate={panelProps.isActive ? 90 : 0}
          />
        )}
        activeKey={col3Key}
        onChange={(key) => setCol3Key(key)}
        size="small"
        items={[
          {
            key: '1',
            label: (
              <label
                className={style.collapse_title}
                style={{
                  color: isDark ? '#fff' : '#000',
                }}
              >
                Доступность территории
              </label>
            ),
            children: (
              <div className={style.collapse_content_wrapper}>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Пешеходная связанность территорий
                  </div>
                  <div className={style.prop_value}>
                    <div style={{ color: 'red', display: 'inline' }}>
                      {!chosenZoom7Hexagon
                        ? Math.round(
                            source.pedestrian_connect / zoom7Hexagons.length
                          )
                        : source.pedestrian_connect}
                    </div>{' '}
                    / 10
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Пробки
                  </div>
                  <div className={style.prop_value}>
                    <div style={{ color: 'red', display: 'inline' }}>
                      {!chosenZoom7Hexagon
                        ? Math.round(source.tj_lvl_max / zoom7Hexagons.length)
                        : source.tj_lvl_max}
                    </div>{' '}
                    / 10
                  </div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Общественный транспорт, маршруты
                  </div>
                  <div className={style.prop_value}>{source.pt_route_cnt}</div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Общественный транспорт, остановки
                  </div>
                  <div className={style.prop_value}>{source.pt_stops_cnt}</div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Парковки
                  </div>
                  <div className={style.prop_value}>{source.parking_cnt}</div>
                </div>
                <div className={style.card}>
                  <div
                    className={style.prop_title}
                    style={{ color: isDark ? 'white' : '#717171' }}
                  >
                    Парковки, места
                  </div>
                  <div className={style.prop_value}>
                    {source.parking_place_cnt}
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default MapDemoHexModal;
