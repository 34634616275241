import { useUnit } from 'effector-react';
import {
  Button,
  Card,
  Checkbox,
  Popover,
  Select,
  Slider,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import Icon, { CheckOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import style from './MapGradient.module.css';
import {
  $activeFilters,
  $chartFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import {
  $blockedBuckets,
  $bucketAlgorithm,
  $gradientBuckets,
  changeBucketAlgorithmEv,
} from '../../models/gradientModel/index.js';
import { showLoaderEv } from '../../models/webSocketModel/index.js';
import {
  $hexagonsHeight,
  $layersOpacity,
  $mapLoaded,
  changeHexagonsHeightEv,
  changeLayersOpacityEv,
  $invertHeight,
  changeInvertHeightEv,
} from '../../models/mapModel/index.js';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $isExtrusion } from '../../models/zoom10Model/index.js';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import LayersController from '../LayersController/LayersController.jsx';
import { public_index_dictionary } from '../../dictionaries/public_index_dictionary.js';
import { $activeLayers } from '../../models/tezberModel/index.js';
import {
  $secondGradient,
  changeSecondGradientEv,
} from '../../models/rbpLandingModel/index.js';
import { $isPreset, $showRoads } from '../../models/rbpLiteModel/index.js';
import RBP_LIte_index_help from '../../dictionaries/RBP_Lite_dict.json';
import { formatNumberCompact } from '../../utils/format-utils.js';

const algorithm_options = [
  {
    label: 'Equal Intervals (EQI)',
    value: 'EQI',
  },
  {
    label: 'Jenks (JNK)',
    value: 'JNK',
  },
  {
    label: 'Quantiles (QNT)',
    value: 'QNT',
  },
];

function OptionsSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8 3h9.75M8 3a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m0 0H1.25M8 15h9.75M8 15a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m0 0H1.25M14 9h3.75M14 9a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0m0 0H1.25"
      />
    </svg>
  );
}

function OptionsIcon() {
  return <Icon style={{ transform: 'scale(1.3)' }} component={OptionsSvg} />;
}

export default function MapGradient() {
  const mapLoaded = useUnit($mapLoaded);
  const gradientBuckets = useUnit($gradientBuckets);
  const activeGradientFilter = useUnit($activeFilters).gradient;
  const activeFilters = useUnit($activeFilters);
  const showLoader = useUnit(showLoaderEv);
  const blockedBuckets = useUnit($blockedBuckets);
  const bucketAlgorithm = useUnit($bucketAlgorithm);
  const changeBucketAlgorithm = useUnit(changeBucketAlgorithmEv);
  const userInfo = useUnit($userInfo);
  const activeAlgorithm = useUnit($bucketAlgorithm);
  const language = useUnit($language);
  const layersOpacity = useUnit($layersOpacity);
  const changeLayersOpacity = useUnit(changeLayersOpacityEv);
  const isExtrusion = useUnit($isExtrusion);
  const changeHexagonsHeight = useUnit(changeHexagonsHeightEv);
  const hexagonsHeight = useUnit($hexagonsHeight);
  const invertHeight = useUnit($invertHeight);
  const changeInvertHeight = useUnit(changeInvertHeightEv);
  const chartFilters = useUnit($chartFilters);
  const { pathname } = useLocation();
  const hideNumbers =
    activeFilters.chosen_metrics[0] === 'income_avg_range' ||
    activeFilters.chosen_metrics[0] === 'rent_price_range';
  const isDark = useUnit($isDarkTheme);
  const activeLayers = useUnit($activeLayers);
  const secondGradient = useUnit($secondGradient);
  const changeSecondGradient = useUnit(changeSecondGradientEv);
  const containerRef = useRef();
  const titleRef = useRef();
  const showRoads = useUnit($showRoads);
  const isPreset = useUnit($isPreset);

  const [blockLevel, setBlockLevel] = useState('zoom_8');

  const bucketsCopy = gradientBuckets.buckets;

  const changeActiveFilter = useUnit(changeActiveFilterEv);

  const onChangeAlgorithm = (value) => {
    changeBucketAlgorithm(value);
  };

  const onInvertChange = (e) => {
    changeInvertHeight(e.target.checked);
  };

  const optionsContent = (
    <div className={style.options_popover}>
      {pathname !== '/tezber' ? (
        <>
          <div className={style.option_title}>
            {ru_en_page_dictionary.algorithm[language]}
          </div>
          <Select
            className={style.options_algorithm}
            options={algorithm_options}
            value={bucketAlgorithm}
            onChange={onChangeAlgorithm}
          />
        </>
      ) : (
        ''
      )}
      <div className={style.option_title}>
        {ru_en_page_dictionary.opacity[language]}
      </div>
      <Slider
        min={0}
        max={1}
        step={0.1}
        defaultValue={0.5}
        onChange={changeLayersOpacity}
        value={layersOpacity}
      />
      {pathname === '/tezber' ? (
        <div onClick={changeSecondGradient} style={{ cursor: 'pointer' }}>
          Переключить градиент
        </div>
      ) : null}
      {pathname !== '/tezber' ? (
        <>
          <div className={style.option_title}>
            {ru_en_page_dictionary.hex_height[language]}
          </div>
          <Slider
            disabled={!isExtrusion}
            min={0}
            max={1000}
            step={100}
            onChange={changeHexagonsHeight}
            value={hexagonsHeight}
          />
          <Checkbox
            checked={invertHeight}
            onChange={onInvertChange}
            disabled={!isExtrusion}
          >
            {ru_en_page_dictionary.invert_height[language]}
          </Checkbox>
        </>
      ) : (
        ''
      )}
    </div>
  );

  useEffect(() => {
    if (window.map) {
      if (mapLoaded) {
        window.map.on('render', () => {
          const zoom = window.map.getZoom();
          let level;
          if (zoom < 11) {
            level = 'zoom_8';
          } else if (zoom >= 11 && zoom < 13) {
            level = 'zoom_9';
          } else {
            level = 'zoom_10';
          }
          if (blockLevel !== level) {
            setBlockLevel(level);
          }
        });
      }
    }
  }, [mapLoaded]);

  let title;
  if (pathname === '/public') {
    title = public_index_dictionary[activeFilters.chosen_metrics[0]];
  } else if (pathname === '/tezber') {
    title = activeLayers.includes('active') ? 'Экономически активное' : 'Общее';
  } else if (pathname === '/lite') {
    if (isPreset) {
      title =
        RBP_LIte_index_help[activeFilters.chosen_metrics[0].replace('d', '')][
          `name_${language}`
        ];
    } else {
      title = public_index_dictionary[activeFilters.chosen_metrics[0]];
    }
    if (showRoads) title = 'Среднесуточная проходимость';
  } else if (activeFilters.chosen_metrics.length > 0) {
    title = activeFilters.chosen_metrics.some((item) => item.includes('index'))
      ? index_names_dynamics[activeFilters.chosen_metrics[0].split('_')[1]][
          `name_${language}`
        ]
      : ru_en_page_dictionary[activeFilters.chosen_metrics]
        ? ru_en_page_dictionary[activeFilters.chosen_metrics][language]
        : '';
  } else if (chartFilters.length > 0) {
    title =
      index_names_dynamics[`d${chartFilters[0].chart}`][`name_${language}`];
  } else {
    title = ru_en_page_dictionary.resilience_index[language];
  }

  return (
    <div className={style.bottom_bar_wrapper}>
      <Card
        className={style.gradient_wrapper}
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            className={style.gradient_top}
            style={{
              justifyContent:
                pathname !== '/public' || pathname === '/lite'
                  ? 'flex-start'
                  : 'center',
            }}
          >
            {pathname !== '/public' &&
            pathname !== '/tezber' &&
            pathname !== '/lite' ? (
              <LayersController />
            ) : (
              ''
            )}
            <div className={style.gradient_by_wrapper}>
              {/* {ru_en_page_dictionary.gradient_by[language]}:{' '} */}
              <Tooltip title={title} placement="top">
                <div
                  style={{ color: isDark ? 'white' : 'black' }}
                  ref={titleRef}
                >
                  {title}
                </div>
              </Tooltip>
            </div>
          </div>
          {/* <div */}
          {/*  style={{ */}
          {/*    display: 'flex', */}
          {/*    justifyContent: 'space-between', */}
          {/*    width: '100%', */}
          {/*  }} */}
          {/* > */}
          {/*  {bucketsCopy.map((item) => { */}
          {/*    return ( */}
          {/*      <div style={{ fontSize: 10 }}> */}
          {/*        {new Intl.NumberFormat('ru-RU', { */}
          {/*          notation: 'compact', */}
          {/*        }).format(item)} */}
          {/*      </div> */}
          {/*    ); */}
          {/*  })} */}
          {/* </div> */}
          <Space
            size="small"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {hideNumbers && (
              <div className={style.bucket_wrapper}>
                <div
                  className={style.bucket}
                  style={{ color: isDark ? 'white' : 'black' }}
                >
                  Низкий
                </div>
              </div>
            )}
            {bucketsCopy.map((item, index) => {
              if (index !== bucketsCopy.length - 1) {
                return (
                  <>
                    {!hideNumbers && (
                      <div className={style.bucket_wrapper}>
                        <div
                          className={style.bucket}
                          style={{ color: isDark ? 'white' : 'black' }}
                        >
                          {formatNumberCompact(
                            item,
                            activeFilters.chosen_metrics[0]
                          )}
                        </div>
                      </div>
                    )}
                    <GradientItem
                      key={`${item}${index}`}
                      maxValue={bucketsCopy[index + 1] || 0}
                      minValue={item}
                      color={gradientBuckets.colors[index]}
                      changeActiveFilter={changeActiveFilter}
                      activeGradientFilter={activeGradientFilter}
                      showLoader={showLoader}
                      blockedBuckets={blockedBuckets[blockLevel]}
                      isLast={index === bucketsCopy.length - 2}
                    />
                    {index === bucketsCopy.length - 2 && !hideNumbers && (
                      <div className={style.bucket_wrapper}>
                        <div
                          className={style.bucket}
                          style={{ color: isDark ? 'white' : 'black' }}
                        >
                          {bucketsCopy[index + 1] === Infinity
                            ? '∞'
                            : formatNumberCompact(
                                bucketsCopy[index + 1],
                                activeFilters.chosen_metrics[0]
                              )}
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            })}
            {hideNumbers && (
              <div className={style.bucket_wrapper}>
                <div
                  className={style.bucket}
                  style={{ color: isDark ? 'white' : 'black' }}
                >
                  Высокий
                </div>
              </div>
            )}
          </Space>
        </div>
      </Card>
      {pathname !== '/public' &&
      // pathname !== '/tezber' &&
      pathname !== '/lite' ? (
        <Popover
          content={optionsContent}
          trigger="click"
          getPopupContainer={() => containerRef.current}
        >
          <Button
            icon={<OptionsIcon />}
            className={style.options_button}
            ref={containerRef}
          />
        </Popover>
      ) : (
        ''
      )}
    </div>
  );
}

function GradientItem({
  color,
  maxValue,
  minValue,
  changeActiveFilter,
  activeGradientFilter,
  blockedBuckets,
  showLoader,
  isLast,
}) {
  const onClick = () => {
    // showLoader();
    changeActiveFilter({
      field: 'gradient',
      value: {
        min: minValue,
        max: maxValue,
      },
    });
  };

  return (
    <div
      className={[
        style.gradient_item,
        activeGradientFilter.some(
          (item) => item.min === minValue && item.max === maxValue
        )
          ? style.active
          : '',
        // !blockedBuckets[`${minValue}-${maxValue}`] ? style.blocked : '',
      ].join(' ')}
      style={{ background: color }}
      // onClick={!blockedBuckets[`${minValue}-${maxValue}`] ? () => {} : onClick}
      onClick={onClick}
    >
      {/* <div className={style.minValue}> */}
      {/*  {new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format( */}
      {/*    minValue */}
      {/*  )} */}
      {/* </div> */}
      {/* {isLast && ( */}
      {/*  <div className={style.maxValue}> */}
      {/*    {new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format( */}
      {/*      maxValue */}
      {/*    )} */}
      {/*  </div> */}
      {/* )} */}
      <div
        className={style.badge}
        style={{
          opacity: activeGradientFilter.some(
            (item) => item.min === minValue && item.max === maxValue
          )
            ? 1
            : 0,
        }}
      >
        <CheckOutlined style={{ fontSize: '13px' }} />
      </div>
    </div>
  );
}
