import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  $requestsList,
  getRequestsListFx,
} from '../../models/requestsModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $language, $userInfo } from '../../models/authModel/index.js';
import style from './RequestsList.module.css';

function RequestsList() {
  const data = useUnit($requestsList);
  const language = useUnit($language);
  const userInfo = useUnit($userInfo);
  const navigate = useNavigate();

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'request_id',
    },
    {
      title: ru_en_page_dictionary.request_name[language],
      dataIndex: 'name',
      key: 'request_name',
    },
    {
      title: ru_en_page_dictionary.request_company[language],
      dataIndex: 'company',
      key: 'request_company',
    },
    {
      title: ru_en_page_dictionary.request_phone[language],
      dataIndex: 'phone',
      key: 'request_phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'request_email',
    },
    {
      title: ru_en_page_dictionary.request_message[language],
      dataIndex: 'message',
      key: 'request_message',
    },
    {
      title: ru_en_page_dictionary.request_created_at[language],
      dataIndex: 'created_at',
      key: 'request_created_at',
    },
  ];

  useEffect(() => {
    getRequestsListFx();
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      if (!userInfo.perms.includes('admin')) {
        navigate('/lite');
      }
    }
  }, [userInfo]);

  return (
    <div>
      <div className={style.requests_list_title}>
        {ru_en_page_dictionary.profile_request_list[language]}
      </div>
      <Table dataSource={data} columns={columns} />
    </div>
  );
}

export default RequestsList;
