import React, { useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import Icon from '@ant-design/icons';
import style from './LandingIsochroneButton.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $mapLoaded,
  changeDrawModeEv,
  changeInteractivityEv,
} from '../../models/mapModel/index.js';
import { getIsochroneForPaymentsEv } from '../../models/isochroneModel/index.js';
import { setChosenPointEv } from '../../models/rbpLandingModel/index.js';
import { $isPreset } from '../../models/rbpLiteModel/index.js';

function MarkerSvg() {
  return (
    <svg
      fill="currentColor"
      width="20"
      height="20"
      viewBox="-3 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m8.075 23.52c-6.811-9.878-8.075-10.891-8.075-14.52 0-4.971 4.029-9 9-9s9 4.029 9 9c0 3.629-1.264 4.64-8.075 14.516-.206.294-.543.484-.925.484s-.719-.19-.922-.48l-.002-.004zm.925-10.77c2.07 0 3.749-1.679 3.749-3.75s-1.679-3.75-3.75-3.75-3.75 1.679-3.75 3.75c0 2.071 1.679 3.75 3.75 3.75z" />
    </svg>
  );
}

function MarkerIcon() {
  return <Icon component={MarkerSvg} />;
}

function LandingIsochroneButton({ absolute = true }) {
  const changeInteractivity = useUnit(changeInteractivityEv);
  const changeDrawModeState = useUnit(changeDrawModeEv);
  const [search] = useSearchParams();
  const getIsochroneForPayments = useUnit(getIsochroneForPaymentsEv);
  const mapLoaded = useUnit($mapLoaded);
  const setChosenPoint = useUnit(setChosenPointEv);
  const isPreset = useUnit($isPreset);
  const { pathname } = useLocation();

  const onClick = () => {
    changeInteractivity(false);
    changeDrawModeState('draw_point');
    window.draw.changeMode('draw_point');
  };

  useEffect(() => {
    if (search.size > 0 && mapLoaded) {
      const coords = queryString.parse(window.location.search);
      getIsochroneForPayments({
        lon: coords.lng,
        lat: coords.lat,
      });
      // window.draw.set({
      //   type: 'FeatureCollection',
      //   features: [
      //     {
      //       type: 'Feature',
      //       geometry: {
      //         type: 'Point',
      //         coordinates: [+coords.lng, +coords.lat],
      //       },
      //       properties: {},
      //     },
      //   ],
      // });
      setChosenPoint(coords);
    }
  }, [search, mapLoaded]);

  if (pathname === '/lite' && isPreset) {
    return null;
  }

  return (
    <div
      className={style.button_wrapper}
      style={{ position: absolute ? 'absolute' : '' }}
    >
      <Tooltip
        title={ru_en_page_dictionary.isochrone_button.ru}
        placement="right"
      >
        <Button
          onClick={onClick}
          icon={<MarkerIcon />}
          className={style.button}
        />
      </Tooltip>
    </div>
  );
}

export default LandingIsochroneButton;
