export const gradient_dictionary = {
  dark: {
    9: [
      'rgba(0, 0, 69, 1)',
      'rgba(33, 12, 74, 1)',
      'rgba(87, 16, 110, 1)',
      'rgba(138, 34, 106, 1)',
      'rgba(188, 55, 85, 1)',
      'rgba(228, 90, 50, 1)',
      'rgba(250, 141, 10, 1)',
      'rgba(250, 202, 51, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    8: [
      'rgba(0, 0, 69, 1)',
      'rgba(41, 11, 84, 1)',
      'rgba(102, 21, 111, 1)',
      'rgba(160, 42, 99, 1)',
      'rgba(213, 72, 66, 1)',
      'rgba(246, 125, 21, 1)',
      'rgba(251, 193, 39, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    7: [
      'rgba(0, 0, 69, 1)',
      'rgba(51, 10, 95, 1)',
      'rgba(121, 28, 110, 1)',
      'rgba(188, 55, 85, 1)',
      'rgba(238, 105, 37, 1)',
      'rgba(228, 237, 194, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    6: [
      'rgba(0, 0, 69, 1)',
      'rgba(66, 10, 104, 1)',
      'rgba(148, 38, 104, 1)',
      'rgba(221, 81, 58, 1)',
      'rgba(253, 165, 10, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    5: [
      'rgba(0, 0, 69, 1)',
      'rgba(87, 16, 110, 1)',
      'rgba(188, 55, 85, 1)',
      'rgba(250, 141, 10, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    4: [
      'rgba(0, 0, 69, 1)',
      'rgba(121, 28, 110, 1)',
      'rgba(238, 105, 37, 1)',
      'rgba(253, 255, 165, 1)',
    ],
    3: ['rgba(0, 0, 69, 1)', 'rgba(188, 55, 85, 1)', 'rgba(253, 255, 165, 1)'],
    2: ['rgba(0, 0, 69, 1)', 'rgba(253, 255, 165, 1)'],
    1: ['rgba(0, 0, 69, 1)'],
  },
  light: {
    9: [
      'rgba(255, 0, 0, 1)',
      'rgba(249, 64, 0, 1)',
      'rgba(243, 130, 0, 1)',
      'rgba(243, 219, 23, 1)',
      'rgba(210, 253, 97, 1)',
      'rgba(104, 202, 57, 1)',
      'rgba(27, 146, 9, 1)',
      'rgba(11, 88, 0, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    8: [
      'rgba(255, 0, 0, 1)',
      'rgba(248, 73, 0, 1)',
      'rgba(241, 144, 0, 1)',
      'rgba(238, 249, 57, 1)',
      'rgba(147, 255, 73, 1)',
      'rgba(34, 163, 27, 1)',
      'rgba(3, 96, 0, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    7: [
      'rgba(255, 0, 0, 1)',
      'rgba(248, 88, 0, 1)',
      'rgba(241, 182, 9, 1)',
      'rgba(210, 253, 97, 1)',
      'rgba(72, 184, 42, 1)',
      'rgba(14, 107, 0, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    6: [
      'rgba(255, 0, 0, 1)',
      'rgba(246, 106, 0, 1)',
      'rgba(246, 239, 42, 1)',
      'rgba(124, 213, 63, 1)',
      'rgba(15, 122, 0, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    5: [
      'rgba(255, 0, 0, 1)',
      'rgba(243, 130, 0, 1)',
      'rgba(210, 253, 97, 1)',
      'rgba(27, 146, 9, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    4: [
      'rgba(255, 0, 0, 1)',
      'rgba(241, 182, 9, 1)',
      'rgba(72, 184, 42, 1)',
      'rgba(3, 31, 3, 1)',
    ],
    3: ['rgba(255, 0, 0, 1)', 'rgba(210, 253, 97, 1)', 'rgba(3, 31, 3, 1)'],
    2: ['rgba(255, 0, 0, 1)', 'rgba(3, 31, 3, 1)'],
    1: ['rgba(255, 0, 0, 1)'],
  },
  lightV2: {
    9: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(0, 149, 255, 0.5)',
      'rgba(71, 178, 255, 0.5)',
      'rgba(94, 202, 239, 0.5)',
      'rgba(240, 216, 30, 0.5)',
      'rgba(255, 188, 0, 0.5)',
      'rgba(255, 137, 3, 0.5)',
      'rgba(255, 43, 0, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    8: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(71, 178, 255, 0.5)',
      'rgba(94, 202, 239, 0.5)',
      'rgba(240, 216, 30, 0.5)',
      'rgba(255, 188, 0, 0.5)',
      'rgba(255, 137, 3, 0.5)',
      'rgba(255, 84, 0, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    7: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(71, 178, 255, 0.5)',
      'rgba(94, 202, 239, 0.5)',
      'rgba(240, 216, 30, 0.5)',
      'rgba(255, 188, 0, 0.5)',
      'rgba(255, 137, 3, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    6: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(71, 178, 255, 0.5)',
      'rgba(240, 216, 30, 0.5)',
      'rgba(255, 188, 0, 0.5)',
      'rgba(255, 84, 0, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    5: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(71, 178, 255, 0.5)',
      'rgba(240, 216, 30, 0.5)',
      'rgba(255, 137, 3, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    4: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(94, 202, 239, 0.5)',
      'rgba(255, 137, 3, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    3: [
      'rgba(0, 102, 255, 0.5)',
      'rgba(255, 188, 0, 0.5)',
      'rgba(255, 0, 0, 0.7)',
    ],
    2: ['rgba(0, 102, 255, 0.5)', 'rgba(255, 0, 0, 0.7)'],
    1: ['rgba(0, 102, 255, 0.5)'],
  },
  public: {
    9: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(92, 178, 195, 0.65)',
      'rgba(138, 200, 171, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(252, 236, 103, 0.65)',
      'rgba(250, 198, 89, 0.65)',
      'rgba(244, 159, 76, 0.65)',
      'rgba(235, 118, 62, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    8: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(92, 178, 195, 0.65)',
      'rgba(138, 200, 171, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(252, 236, 103, 0.65)',
      'rgba(250, 198, 89, 0.65)',
      'rgba(235, 118, 62, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    7: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(92, 178, 195, 0.65)',
      'rgba(138, 200, 171, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(252, 236, 103, 0.65)',
      'rgba(235, 118, 62, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    6: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(92, 178, 195, 0.65)',
      'rgba(138, 200, 171, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(252, 236, 103, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    5: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(138, 200, 171, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(252, 236, 103, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    4: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(232, 244, 123, 0.65)',
      'rgba(244, 159, 76, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    3: [
      'rgba(48, 156, 219, 0.65)',
      'rgba(249, 198, 151, 0.65)',
      'rgba(224, 69, 53, 0.65)',

      'rgba(48, 156, 219, 0.65)',
      'rgba(250, 198, 89, 0.65)',
      'rgba(224, 69, 53, 0.65)',
    ],
    2: ['rgba(48, 156, 219, 0.65)', 'rgba(224, 69, 53, 0.65)'],
    1: ['rgba(48, 156, 219, 0.65)'],
  },
};

export const gradient_colors = {
  dark: [
    // 'rgba(0, 0, 69, 1)',
    // 'rgba(27, 12, 66, 1)',
    // 'rgba(76, 12, 107, 1)',
    // 'rgba(121, 28, 110, 1)',
    // 'rgba(166, 45, 97, 1)',
    // 'rgba(207, 68, 71, 1)',
    // 'rgba(238, 105, 37, 1)',
    // 'rgba(252, 155, 6, 1)',
    // 'rgba(248, 209, 60, 1)',
    // 'rgba(253, 255, 165, 1)',

    // 'rgba(181, 55, 76, 1)',
    // 'rgba(155, 51, 76, 1)',
    // 'rgba(130, 54, 84, 1)',
    // 'rgba(120, 69, 100, 1)',
    // 'rgba(105, 88, 122, 1)',
    // 'rgba(98, 102, 137, 1)',
    // 'rgba(89, 123, 160, 1)',
    // 'rgba(98, 153, 158, 1)',
    // 'rgba(102, 184, 156, 1)',
    // 'rgba(120, 218, 157, 1)',

    'rgba(0, 0, 69, 1)',
    'rgba(27, 12, 66, 1)',
    'rgba(76, 12, 107, 1)',
    'rgba(121, 28, 110, 1)',
    'rgba(166, 45, 97, 1)',
    'rgba(207, 68, 71, 1)',
    'rgba(238, 105, 37, 1)',
    'rgba(252, 155, 6, 1)',
    'rgba(248, 209, 60, 1)',
    'rgba(253, 255, 165, 1)',
  ],
  light: [
    // 'rgba(255, 0, 0, 1)',
    // 'rgba(255, 57, 0, 1)',
    // 'rgba(255, 113, 0, 1)',
    // 'rgba(255, 170, 0, 1)',
    // 'rgba(255, 227, 0, 1)',
    // 'rgba(216, 233, 0, 1)',
    // 'rgba(148, 190, 0, 1)',
    // 'rgba(92, 147, 0, 1)',
    // 'rgba(49, 103, 13, 1)',
    // 'rgba(20, 60, 0, 1)',

    // 'rgba(183, 57, 78, 1)',
    // 'rgba(189, 75, 75, 1)',
    // 'rgba(205, 113, 74, 1)',
    // 'rgba(222, 153, 78, 1)',
    // 'rgba(233, 186, 82, 1)',
    // 'rgba(221, 184, 80, 1)',
    // 'rgba(202, 181, 76, 1)',
    // 'rgba(174, 177, 72, 1)',
    // 'rgba(148, 175, 68, 1)',
    // 'rgba(129, 172, 64, 1)',

    'rgba(255, 0, 0, 1)',
    'rgba(249, 55, 0, 1)',
    'rgba(243, 117, 0, 1)',
    'rgba(241, 182, 9, 1)',
    'rgba(231, 250, 66, 1)',
    'rgba(161, 232, 77, 1)',
    'rgba(72, 184, 42, 1)',
    'rgba(13, 133, 1, 1)',
    'rgba(0, 84, 0, 1)',
    'rgba(3, 31, 3, 1)',
  ],

  lightV2: [
    'rgba(0, 102, 255, 0.5)',
    'rgba(0, 149, 255, 0.5)',
    'rgba(71, 178, 255, 0.5)',
    'rgba(94, 202, 239, 0.5)',
    'rgba(240, 216, 30, 0.5)',
    'rgba(255, 188, 0, 0.5)',
    'rgba(255, 137, 3, 0.5)',
    'rgba(255, 84, 0, 0.5)',
    'rgba(255, 43, 0, 0.5)',
    'rgba(255, 0, 0, 0.7)',
  ],

  public: [
    'rgba(48, 156, 219, 0.65)',
    'rgba(92, 178, 195, 0.65)',
    'rgba(138, 200, 171, 0.65)',
    'rgba(185, 222, 147, 0.65)',
    'rgba(232, 244, 123, 0.65)',
    'rgba(252, 236, 103, 0.65)',
    'rgba(250, 198, 89, 0.65)',
    'rgba(244, 159, 76, 0.65)',
    'rgba(235, 118, 62, 0.65)',
    'rgba(224, 69, 53, 0.65)',
  ],
};
