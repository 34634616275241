export const darkTheme = {
  token: {
    // colorPrimary: 'black',
    colorBgBase: '#1E2328',
    colorTextBase: '#fff',
  },
  components: {
    Radio: {
      colorPrimary: '#2D9CDB',
    },
    Slider: {
      dotBorderColor: '#f0f0f0',
      colorFillContentHover: '#9d9d9d',
      handleActiveColor: '#2D9CDB',
    },
    Segmented: {
      itemSelectedBg: '#D3D3D34D',
    },
    Switch: {
      colorPrimary: '#2D9CDB',
      colorPrimaryHover: '#2D9CDB',
    },
    Divider: {
      colorSplit: '#242424',
    },
    Spin: {
      colorPrimary: '#2D9CDB',
    },
    Input: {
      colorBorder: 'white',
      paddingBlock: 4,
      paddingInline: 11,
    },
    InputNumber: {
      colorBorder: 'white',
    },
    Tree: {
      colorBorder: 'white',
      colorPrimary: '#2D9CDB',
      colorPrimaryHover: '#2D9CDB',
    },
    Checkbox: {
      colorBorder: 'white',
      colorPrimary: '#2D9CDB',
      colorPrimaryHover: '#2D9CDB',
    },
    Card: {
      colorBorderSecondary: 'transparent',
    },
    TreeSelect: {
      colorBorder: 'transparent',
      nodeSelectedBg: 'rgba(255, 255, 255, 0.04)',
    },
    Select: {
      colorBorder: '#BDBDBD',
      colorPrimaryHover: '#2D9CDB',
      optionSelectedBg: 'rgba(255, 255, 255, 0.04)',
    },
    Button: {
      defaultBorderColor: 'transparent',
      colorPrimary: '#2D9CDB',
      defaultColor: '#D3D3D3',
      colorPrimaryHover: '#56b7e8',
      defaultShadow: 'transparent',
      primaryShadow: 'transparent',
    },
    Layout: {
      headerBg: '#252525',
      colorBgLayout: '#181A1F',
      algorithm: true,
      headerColor: '#D3D3D3',
    },
    Typography: {
      colorTextBase: '#2D9CDB',
      algorithm: true,
    },
    Tooltip: {
      colorBgSpotlight: '#2D9CDB',
      colorText: 'white',
    },
    Table: {
      rowSelectedBg: '#D3D3D34D',
      rowSelectedHoverBg: 'rgba(255,255,255,0.3)',
    },
  },
};

export const lightTheme = {
  token: {
    colorPrimary: '#2D9CDB',
    colorBgBase: 'white',
    colorTextBase: '#2D9CDB',
  },
  components: {
    Radio: {
      colorPrimary: '#2D9CDB',
    },
    Switch: {
      colorPrimary: '#2D9CDB',
    },
    Divider: {
      colorSplit: '#E8E8E8',
    },
    Tree: {
      colorPrimary: '#2D9CDB',
      colorPrimaryHover: '#2D9CDB',
    },
    Drawer: {
      colorText: 'black',
    },
    Checkbox: {
      colorText: '#717171',
    },
    Button: {
      defaultColor: '#717171',
    },
    Layout: {
      headerBg: 'white',
      colorBgLayout: '#f0f1f2',
      algorithm: true,
      headerColor: '#717171',
    },
    Typography: {
      colorTextBase: '#2D9CDB',
      algorithm: true,
    },
    Segmented: {
      itemSelectedBg: 'rgba(142,212,255,0.73)',
    },
    Tooltip: {
      colorBgSpotlight: '#2D9CDB',
      colorText: 'white',
    },
    Input: {
      paddingBlock: 4,
      paddingInline: 11,
    },
  },
};

export const addResponsiveRules = (theme, width) => {
  if (width <= 1200) {
    return {
      ...theme,
      token: {
        ...theme.token,
        fontSize: '0.625rem',
        fontSizeLG: '0.75rem',
      },
      components: {
        ...theme.components,
        Table: {
          ...theme.components.Table,
          cellPaddingBlock: 4,
          cellPaddingInline: 4,
        },
      },
    };
  }
  if (width <= 1500) {
    return {
      ...theme,
      token: {
        ...theme.token,
        fontSize: '0.75rem',
        fontSizeLG: '0.875rem',
      },
      components: {
        ...theme.components,
        Table: {
          ...theme.components.Table,
          cellPaddingBlock: 8,
          cellPaddingInline: 8,
        },
      },
    };
  }
  if (width <= 1700) {
    return {
      ...theme,
      token: {
        ...theme.token,
        fontSize: '0.875rem',
        fontSizeLG: '1rem',
      },
      components: {
        ...theme.components,
        Table: {
          ...theme.components.Table,
          cellPaddingBlock: 8,
          cellPaddingInline: 8,
        },
      },
    };
  }
  if (width <= 1920) {
    return {
      ...theme,
      token: {
        ...theme.token,
        fontSize: '0.875rem',
        fontSizeLG: '1rem',
      },
      components: {
        ...theme.components,
      },
    };
  }
  return theme;
};
