import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import mapboxgl from 'mapbox-gl';
import {
  $activeLayers,
  $tableChosenDots,
  $tableOurChosenDots,
  $tezberDots,
} from '../../../models/tezberModel/index.js';
import { $interactivity, $mapLoaded } from '../../../models/mapModel/index.js';
import { changeLayerVisibility } from '../../../utils/mapbox-utils.js';

const handleClickCluster = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: ['tezber_dots_layer'],
  });
  const clusterId = features[0].properties.cluster_id;
  window.map
    .getSource('tezber_dots_source')
    .getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      window.map.easeTo({
        center: features[0].geometry.coordinates,
        zoom,
      });
    });
};

const handleClickDot = (e) => {
  const feature = e.features[0];
  const coordinates = feature.geometry.coordinates.slice();

  const html = `
            <div class="popup_content_wrapper">
              <div class="popup_title">Существующие ПВЗ</div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Название ПВЗ:</div> 
                <div class="popup_item_value">${
                  feature.properties.name || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Охват ЦА:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_18_60 || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Охват населения:</div> 
                <div class="popup_item_value">${
                  feature.properties.pop_total || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Грав. модель ЦА:</div> 
                <div class="popup_item_value">${
                  feature.properties.GRAV_pop_18_60 || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Грав. модель общий:</div> 
                <div class="popup_item_value">${
                  feature.properties.GRAV_pop_total || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_odd">
                <div class="popup_item_field">Регион:</div> 
                <div class="popup_item_value">${
                  feature.properties.region || '-'
                }</div>
              </div>
              <div class="popup_item_wrapper popup_item_even">
                <div class="popup_item_field">Населенный пункт:</div> 
                <div class="popup_item_value">${
                  feature.properties.settlement || '-'
                }</div>
              </div>
            </div>`;

  if (document.getElementsByClassName('mapboxgl-popup'))
    new mapboxgl.Popup().setLngLat(coordinates).setHTML(html).addTo(window.map);
};

function TezberLayer() {
  const tezberDots = useUnit($tezberDots);
  const mapLoaded = useUnit($mapLoaded);
  const tableChosenDots = useUnit($tableChosenDots);
  const activeLayers = useUnit($activeLayers);
  const interactivity = useUnit($interactivity);

  const createLayer = (update) => {
    const tezberDotsData = {
      type: 'FeatureCollection',
      features:
        tableChosenDots.length > 0
          ? tezberDots.filter((item) =>
              tableChosenDots.includes(item.properties.name)
            )
          : tezberDots,
    };
    if (update) {
      window.map.getSource('tezber_dots_source').setData(tezberDotsData);
    } else {
      if (!window.map.getSource('tezber_dots_source')) {
        window.map.addSource('tezber_dots_source', {
          type: 'geojson',
          data: tezberDotsData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
      }
      if (!window.map.getLayer('tezber_dots_layer')) {
        // window.map.addLayer({
        //   id: 'tezber_dots_layer',
        //   type: 'circle',
        //   source: 'tezber_dots_source',
        //   paint: {
        //     'circle-radius': {
        //       base: 1.75,
        //       stops: [
        //         [12, 2],
        //         [22, 180],
        //       ],
        //     },
        //     'circle-color': '#3bb2d0',
        //   },
        // });

        // FIXME Cluster layer
        window.map.addLayer({
          id: 'tezber_dots_layer',
          type: 'circle',
          source: 'tezber_dots_source',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#3bb2d0',
            'circle-radius': 10,
          },
        });

        // FIXME Cluster count layer
        window.map.addLayer({
          id: 'tezber_dots_count_layer',
          type: 'symbol',
          source: 'tezber_dots_source',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-size': 12,
          },
        });

        // FIXME Unclustered dots layer
        window.map.addLayer({
          id: 'tezber_dots_unclustered_layer',
          type: 'circle',
          source: 'tezber_dots_source',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#3bb2d0',
            'circle-radius': 10,
          },
        });

        // FIXME Zoom in on cluster click + cursor on hover
        window.map.on('click', 'tezber_dots_layer', handleClickCluster);

        // window.map.on('mouseenter', 'tezber_dots_layer', () => {
        //   window.map.getCanvas().style.cursor = 'pointer';
        // });
        //
        // window.map.on('mouseleave', 'tezber_dots_layer', () => {
        //   window.map.getCanvas().style.cursor = '';
        // });

        // FIXME Popup on dot click + cursor on hover
        window.map.on('click', 'tezber_dots_unclustered_layer', handleClickDot);

        // window.map.on('mouseenter', 'tezber_dots_unclustered_layer', () => {
        //   window.map.getCanvas().style.cursor = 'pointer';
        // });
        //
        // window.map.on('mouseleave', 'tezber_dots_unclustered_layer', () => {
        //   window.map.getCanvas().style.cursor = '';
        // });

        setTimeout(() => {
          window.map.moveLayer(`tezber_dots_unclustered_layer`);
          window.map.moveLayer(`tezber_dots_layer`);
          window.map.moveLayer(`tezber_dots_count_layer`);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded && tezberDots) {
      if (window.map.getLayer('tezber_dots_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [tezberDots, mapLoaded, tableChosenDots]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('tezber_dots_layer')) {
      if (!activeLayers.includes('tezber_dots_layer')) {
        changeLayerVisibility('tezber_dots_layer', false);
        changeLayerVisibility('tezber_dots_count_layer', false);
        changeLayerVisibility('tezber_dots_unclustered_layer', false);
      } else {
        changeLayerVisibility('tezber_dots_layer', true);
        changeLayerVisibility('tezber_dots_count_layer', true);
        changeLayerVisibility('tezber_dots_unclustered_layer', true);
      }
    }
  }, [mapLoaded, activeLayers]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer(`tezber_dots_layer`)) {
      if (interactivity) {
        window.map.on('click', `tezber_dots_layer`, handleClickCluster);
        window.map.on('click', `tezber_dots_unclustered_layer`, handleClickDot);
      } else {
        window.map.off('click', `tezber_dots_layer`, handleClickCluster);
        window.map.off(
          'click',
          `tezber_dots_unclustered_layer`,
          handleClickDot
        );
      }
    }
  }, [mapLoaded, interactivity]);

  return null;
}

export default TezberLayer;
