import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import { $userInfo } from '../../models/authModel/index.js';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import PlacesSearch from '../../components/TezberComponents/PlacesSearch/PlacesSearch.jsx';
import DiffChart from '../../components/TezberComponents/DiffChart/DiffChart.jsx';
import PlacesTable from '../../components/TezberComponents/PlacesTable/PlacesTable.jsx';
import { getTezberDataFx } from '../../models/tezberModel/index.js';

const authorizedUsers = [
  'kima@frontier.kz',
  'styupanov@qala-ai.com',
  'sevasresident@gmail.com',
  'tezber@gmail.com',
  'a.mukashev@tezber.kz',
  'r.tursunov@tezber.kz',
  'i.gladkih@tezber.kz',
  'petrdzb@tezber.kz',
  'd.nurtliyev@tezber.kz',
];

function Tezber() {
  const userInfo = useUnit($userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      if (!authorizedUsers.includes(userInfo.email)) {
        navigate('/lite');
      }
    }
  }, [userInfo]);

  useEffect(() => {
    getTezberDataFx();
  }, []);

  return (
    <Row gutter={[10, 10]} style={{ height: '100%', position: 'relative' }}>
      <Col span={6} style={{ height: '55vh' }}>
        <PlacesSearch />
      </Col>
      <Col span={18} style={{ height: '55vh' }}>
        <Card
          id="map_container"
          className="map_card"
          bodyStyle={{ height: '100%' }}
        >
          <MapBlock />
        </Card>
      </Col>
      <Col span={6} style={{ height: '35vh' }}>
        <DiffChart />
      </Col>
      <Col span={18} style={{ height: '35vh' }}>
        <PlacesTable />
      </Col>
    </Row>
  );
}

export default Tezber;
