import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { length } from '@turf/turf';
import { $mapLoaded } from '../../../models/mapModel/index.js';
import {
  $measureMode,
  changeCurrentMeasureEv,
} from '../../../models/tezberModel/index.js';

const linestring = {
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};

const handleMeasure = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: ['measure-points'],
  });

  const geojson = window.map.getSource('geojson')._data;

  // Remove the linestring from the group
  // so we can redraw it based on the points collection.
  if (geojson.features.length > 1) geojson.features.pop();

  // Clear the distance container to populate it with a new value.
  changeCurrentMeasureEv(0);
  // If a feature was clicked, remove it from the map.
  if (features.length) {
    const { id } = features[0].properties;
    geojson.features = geojson.features.filter(
      (point) => point.properties.id !== id
    );
  } else {
    const point = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: {
        id: String(new Date().getTime()),
      },
    };

    geojson.features.push(point);
  }

  if (geojson.features.length > 1) {
    linestring.geometry.coordinates = geojson.features.map(
      (point) => point.geometry.coordinates
    );

    geojson.features.push(linestring);

    // Populate the distanceContainer with total distance
    const distance = length(linestring);
    changeCurrentMeasureEv(`${distance.toLocaleString()} км`);
  }
  window.map.getSource('geojson').setData(geojson);
};

const handleCursor = (e) => {
  const features = window.map.queryRenderedFeatures(e.point, {
    layers: ['measure-points'],
  });
  // Change the cursor to a pointer when hovering over a point on the map.
  // Otherwise cursor is a crosshair.
  window.map.getCanvas().style.cursor = features.length
    ? 'pointer'
    : 'crosshair';
};

function MeasureLayer() {
  const mapLoaded = useUnit($mapLoaded);
  const measureMode = useUnit($measureMode);

  const createLayer = () => {
    window.map.addSource('geojson', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    window.map.addLayer({
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#000',
      },
      filter: ['in', '$type', 'Point'],
    });
    window.map.addLayer({
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': 2.5,
      },
      filter: ['in', '$type', 'LineString'],
    });
  };

  useEffect(() => {
    if (mapLoaded) {
      setTimeout(() => {
        createLayer();
      }, 500);
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapLoaded) {
      if (measureMode) {
        window.map.on('click', handleMeasure);
        window.map.on('mousemove', handleCursor);
      } else {
        window.map.off('click', handleMeasure);
        window.map.off('mousemove', handleCursor);
        window.map.on('mousemove', () => {
          window.map.getCanvas().style.cursor = 'default';
        });
      }
    }
  }, [measureMode, mapLoaded]);

  return null;
}

export default MeasureLayer;
